import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import moment from '../../../utils/moment';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconButton: {
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad'
    }
  },
  toolbarLabel: {
    marginLeft: theme.spacing(1)
  }
}));

interface ToolbarItemProps extends PropsWithChildren<unknown> {
  date: Date;
  changeDate: (increase: boolean) => void;
}

const Toolbar: FunctionComponent<ToolbarItemProps> = (props) => {
  const classes = useStyles();
  const { date, changeDate, ...rest } = props;

  return (
    <div {...rest}>
      <Box className={classes.toolbar}>
        <Box className={classes.buttonGroup}>
          <div className={classes.iconButton} onClick={() => changeDate(false)}>
            <IconButton aria-label="left" size="medium">
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </div>
          <div className={classes.iconButton} onClick={() => changeDate(true)}>
            <IconButton aria-label="right" size="medium">
              <ArrowForwardIcon fontSize="small" />
            </IconButton>
          </div>

          <Typography
            className={classes.toolbarLabel}
            color="textSecondary"
            variant="body1"
          >
            {moment(date).format('MMMM yyyy')}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Toolbar;
