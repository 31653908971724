import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement
} from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {}, // NOTE: to prevent prevent warning in console log
  icon: {}, // NOTE: to prevent warning in console log
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

interface NavItemProps extends PropsWithChildren<unknown> {
  href: string;
  icon: ReactElement<SvgIconProps>;
  title: string;
}

const NavItem: FunctionComponent<NavItemProps> = (props) => {
  const classes = useStyles();
  const { href, icon, title, ...rest } = props;

  return (
    <ListItem
      button
      activeClassName={classes.active}
      component={NavLink}
      key={title}
      to={href}
      {...rest}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default NavItem;
