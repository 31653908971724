import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect
} from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useAppContext } from '../../../context/app-context';
import * as calendarActions from '../../../context/calendar/actions';
import { CalendarType } from '../../../types';
import EditRoom from './edit-room';
import Results from './results';
import Toolbar from './toolbar';
import * as firebase from '../../../services/firebase';
import { getDarkBackground } from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CalendarListView: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useAppContext();
  const [filteredCalendars, setFilteredCalendars] = useState(
    state.calendar.calendars
  );
  const [editRoomOpen, setEditRoomOpen] = useState(false);

  useEffect(() => {
    setFilteredCalendars(state.calendar.calendars);
  }, [state.calendar.calendars]);

  const filterCalendars = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilteredCalendars(
      state.calendar.calendars.filter((c: CalendarType) =>
        c.summary.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  const closeDialog = () => {
    setEditRoomOpen(false);
    dispatch(calendarActions.setCurrentSelectedCalendar(null));
  };

  const saveCalendar = (calendar: CalendarType) => {
    setEditRoomOpen(false);
    const calendarExtrasRef = firebase.getExtrasDbRef(
      state.auth.user.uid,
      calendar.id
    );
    calendarExtrasRef.set(calendar.extras);
    dispatch(calendarActions.setCurrentSelectedCalendar(null));
  };

  const editCalendarExtras = (calendar: CalendarType) => {
    setEditRoomOpen(true);
    dispatch(calendarActions.setCurrentSelectedCalendar(calendar));
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Toolbar filterCalendars={filterCalendars} />
      <Box mt={3}>
        <Results
          editCalendarExtras={editCalendarExtras}
          calendars={filteredCalendars}
        />
      </Box>
      {state.calendar.currentSelectedCalendar ? (
        <EditRoom
          open={editRoomOpen}
          fullScreen={fullScreen}
          closeDialog={closeDialog}
          saveCalendar={saveCalendar}
          calendar={state.calendar.currentSelectedCalendar}
        />
      ) : null}
    </Container>
  );
};

export default CalendarListView;
