import React, {
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  IconButton,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {
  ToolbarProps,
  NavigateAction,
  View,
  Messages
} from 'react-big-calendar';
import { CalendarEvent } from '../../../types';

const NavigateType = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE'
};

const useStyles = makeStyles((theme: Theme) => ({
  rbcToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  rbcButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rbcButton: {
    marginRight: theme.spacing(1),
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad'
    }
  },
  rbcIconButton: {
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad'
    }
  },
  rbcToolbarLabel: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80
  }
}));

interface MessagesType {
  [key: string]: string;
}

const CalenderToolbar: FunctionComponent<
  ToolbarProps<CalendarEvent, PropsWithChildren<unknown>>
> = (props) => {
  const classes = useStyles();
  const navigate = (action: NavigateAction) => {
    props.onNavigate(action);
  };

  const handleViewClick = (e: ChangeEvent<{ value: unknown }>) => {
    props.onView(e.target.value as View);
  };

  const viewNamesGroup = (messages: Messages) => {
    const { view, views } = props;

    if ((views as string[]).length > 1) {
      return (
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={view}
            onChange={(event: ChangeEvent<{ value: unknown }>) =>
              handleViewClick(event)
            }
          >
            {(views as string[]).map((name: string) => (
              <MenuItem key={name} value={name}>
                {(messages as MessagesType)[name]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <Box className={classes.rbcToolbar}>
      <Box className={classes.rbcButtonGroup}>
        <div
          className={classes.rbcButton}
          onClick={() => navigate(NavigateType.TODAY as NavigateAction)}
        >
          <Button variant="outlined" size="medium" color="default">
            {props.localizer.messages.today}
          </Button>
        </div>
        <div
          className={classes.rbcIconButton}
          onClick={() => navigate(NavigateType.PREVIOUS as NavigateAction)}
        >
          <IconButton aria-label="left" size="medium">
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </div>
        <div
          className={classes.rbcIconButton}
          onClick={() => navigate(NavigateType.NEXT as NavigateAction)}
        >
          <IconButton aria-label="right" size="medium">
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </div>

        <Typography
          className={classes.rbcToolbarLabel}
          color="textSecondary"
          variant="body1"
        >
          {props.label}
        </Typography>
      </Box>

      <div>{viewNamesGroup(props.localizer.messages)}</div>
    </Box>
  );
};

export default CalenderToolbar;
