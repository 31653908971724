import produce from 'immer';
import { ActionType, AppActions, AppState } from './types';

export const initialAppState: AppState = {
  date: new Date(),
  isInitializing: true
};

export const appReducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case ActionType.SET_DATE: {
      return produce(state, (draftState) => {
        draftState.date = new Date(action.payload);
      });
    }
    case ActionType.SET_IS_INITIALIZING: {
      return produce(state, (draftState) => {
        draftState.isInitializing = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};
