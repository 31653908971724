import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Avatar } from '@material-ui/core';

const Logo: FunctionComponent<PropsWithChildren<unknown>> = (props) => {
  return (
    <Avatar
      alt="Logo"
      src="/images/digisign-avatar-logo.png"
      style={{
        backgroundColor: '#ffffff',
        boxShadow: '0 0 1px  rgba(0,0,0,0.6)'
      }}
      {...props}
    />
  );
};

export default Logo;
