import React, {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useState
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  RecurringEventActionType,
  RecurringEventSaveActionEnum
} from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

interface RecurringEventChangedProps extends PropsWithChildren<unknown> {
  closeDialog: () => void;
  fullScreen: boolean;
  open: boolean;
  recurrenceHasChanged: boolean;
  type: RecurringEventActionType;
  updateAction: (eventType: string) => void;
}

const RecurringEventChanged = forwardRef(
  (props: RecurringEventChangedProps, ref: Ref<unknown>) => {
    const classes = useStyles();
    const {
      closeDialog,
      fullScreen,
      open,
      recurrenceHasChanged,
      type,
      updateAction
    } = props;
    const [eventTypeValue, setEventTypeValue] = useState<string>(
      recurrenceHasChanged
        ? RecurringEventSaveActionEnum.ALL_EVENTS
        : RecurringEventSaveActionEnum.THIS_EVENT
    );

    const handleEventTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
      setEventTypeValue(event.target.defaultValue);
    };

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => closeDialog()}
        aria-labelledby="recurring-event-changed"
        ref={ref}
      >
        <DialogTitle id="recurring-event-changed-dialog-title">{`${
          type.substr(0, 1).toUpperCase() + type.substr(1).toLowerCase()
        } recurring event`}</DialogTitle>
        <DialogContent className={classes.content}>
          <RadioGroup
            aria-label="recurring-event-type"
            name="recurring-event-type"
            value={eventTypeValue}
            onChange={handleEventTypeChange}
          >
            {recurrenceHasChanged ? null : (
              <FormControlLabel
                value={RecurringEventSaveActionEnum.THIS_EVENT}
                control={<Radio />}
                label="This event"
              />
            )}
            <FormControlLabel
              value={RecurringEventSaveActionEnum.ALL_EVENTS}
              control={<Radio />}
              label="All events"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => updateAction(eventTypeValue)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default RecurringEventChanged;
