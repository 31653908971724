import React, {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import FileSizeAlertDialog from '../../..//components/dialog/file-size-alert';
import LogoGrid from '../../../components/image/logo-grid';
import LogoListItem from '../../..//components/image/logo-list-item';
import * as firebase from '../../../services/firebase';
import { TeamType, User } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    minWidth: 600
  },
  title: {
    flex: 1
  },
  content: {
    padding: theme.spacing(2)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  textField: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2)
  }
}));

interface EditTeamProps extends PropsWithChildren<unknown> {
  team: TeamType;
  user: User;
  fullScreen: boolean;
  open: boolean;
  closeDialog: () => void;
  saveTeam: (team: TeamType) => void;
}

const EditTeam = forwardRef((props: EditTeamProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const theme = useTheme();
  const logoGridFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { team, user, open, fullScreen, closeDialog, saveTeam } = props;
  const [name, setName] = useState<string>(team.name as string);
  const [description, setDescription] = useState<string>(
    team.description as string
  );
  const [logoURL, setLogoURL] = useState<string>(team.logoURL as string);
  const [logoGridOpen, setLogoGridOpen] = useState<boolean>(false);
  const [imageUrlList, setImageUrlList] = useState<string[]>([]);
  const [fileSizeAlertOpen, setFileSizeAlertOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const listUserTeamLogos = async () => {
    const tempImageUrlList: string[] = [];
    const storageItems = await firebase.getUserTeamLogosRef(user.uid).listAll();
    for (const imageRef of storageItems.items) {
      tempImageUrlList.push(await imageRef.getDownloadURL());
    }
    setImageUrlList(tempImageUrlList);
  };

  useEffect(() => {
    listUserTeamLogos();
  }, [user]);

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const selectLogo = (selectedLogoURL: string) => {
    setLogoURL(selectedLogoURL);
    setLogoGridOpen(false);
  };

  const closeLogoGrid = () => {
    setLogoGridOpen(false);
  };

  const isEmpty = (value: string) => {
    return !value || value.trim() === '';
  };

  const isSaveDisabled = () => {
    return isEmpty(name) || isEmpty(description) || isEmpty(logoURL);
  };

  const save = () => {
    const updatedTeam: TeamType = {
      id: team.id ? team.id : null,
      name: name.trim(),
      description: description.trim(),
      logoURL
    };
    saveTeam(updatedTeam);
  };

  const handleFireBaseUpload = (image: File) => {
    setUploading(true);
    const imageExt = image.name.split('.').pop();
    const newFilename = `${uuid()}.${imageExt}`;
    // async magic goes here...
    const uploadTask = firebase
      .getUserTeamLogosRef(user.uid)
      //      .child(image.name)
      .child(newFilename)
      .put(image);
    // initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      () => {
        // ongoing updates, do nothing
      },
      () => {
        // catches the errors
        setUploading(false);
      },
      () => {
        setUploading(false);

        // reload images
        listUserTeamLogos();

        firebase
          .getUserTeamLogosRef(user.uid)
          .child(newFilename)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setLogoURL(fireBaseUrl);
          });
      }
    );
  };

  const handleCloseFileSizeAlert = () => {
    setFileSizeAlertOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {team.name ? team.name : 'New team'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <DialogContentText>{`Edit the team's information.`}</DialogContentText>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Name *" secondary="Team name." />
            <TextField
              className={classes.textField}
              error={name !== null && isEmpty(name)}
              fullWidth={true}
              id="name-required"
              variant="outlined"
              value={name ? name : ''}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleNameChange(e)}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Description *"
              secondary="Additional info to team."
            />
            <TextField
              className={classes.textField}
              error={description !== null && isEmpty(description)}
              fullWidth={true}
              id="description-required"
              variant="outlined"
              value={description ? description : ''}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleDescriptionChange(e)}
            />
          </ListItem>
          <Divider />
          <LogoListItem
            handleFireBaseUpload={handleFireBaseUpload}
            imageUrlList={imageUrlList}
            listItemClass={classes.listItem}
            logoURL={logoURL}
            setFileSizeAlertOpen={setFileSizeAlertOpen}
            setLogoGridOpen={setLogoGridOpen}
            uploading={uploading}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="secondary">
          Cancel
        </Button>
        <Button disabled={isSaveDisabled()} onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
      {logoGridOpen ? (
        <LogoGrid
          open={logoGridOpen}
          fullScreen={logoGridFullScreen}
          closeDialog={closeLogoGrid}
          selectLogo={selectLogo}
          imageUrlList={imageUrlList}
        />
      ) : null}
      {fileSizeAlertOpen ? (
        <FileSizeAlertDialog
          open={fileSizeAlertOpen}
          handleClose={handleCloseFileSizeAlert}
        />
      ) : null}
    </Dialog>
  );
});

export default EditTeam;
