import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import InputIcon from '@material-ui/icons/Input';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import TabletIcon from '@material-ui/icons/Tablet';
import NavItem from './navitem';
import * as google from '../../services/google';
import { useAppContext } from '../../context/app-context';
import * as authActions from '../../context/auth/actions';
import * as teamActions from '../../context/team/actions';

const items = [
  {
    href: '/app/dashboard',
    icon: <BarChartIcon />,
    title: 'Dashboard'
  },
  {
    href: '/app/booking',
    icon: <CalendarIcon />,
    title: 'Booking'
  },
  {
    href: '/app/rooms',
    icon: <MeetingRoomIcon />,
    title: 'Rooms'
  },
  {
    href: '/app/teams',
    icon: <PeopleOutlineIcon />,
    title: 'Teams'
  },
  {
    href: '/app/devices',
    icon: <TabletIcon />,
    title: 'Devices'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(1)
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

interface NavBarProps extends PropsWithChildren<unknown> {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: FunctionComponent<NavBarProps> = ({
  onMobileClose,
  openMobile
}) => {
  const { state, dispatch } = useAppContext();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const logOut = async () => {
    await google.signOut();
    // reset teams when logging out
    dispatch(teamActions.setTeams([]));
    // to prevent that calendars are fetch when logging off
    dispatch(authActions.setIsAuthenticated(false));
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={
            state.auth.user.photoURL
              ? state.auth.user.photoURL
              : '/images/digisign-avatar-logo.png'
          }
          to="/app"
        />
        <Typography color="textPrimary" variant="h5">
          {state.auth.user.displayName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {state.auth.user.email}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2}>
        <List>
          <ListItem button onClick={logOut}>
            <ListItemIcon>
              <InputIcon />
            </ListItemIcon>
            <ListItemText primary={`Logout`} />
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp={true}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onMobileClose()}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown={true}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={true}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
