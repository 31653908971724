import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Container,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { useAppContext } from '../../../context/app-context';
import * as appActions from '../../../context/app/actions';
import Toolbar from './toolbar';
import { CalendarType, CalendarEvent } from '../../../types';
import moment from '../../../utils/moment';
import { getDarkBackground } from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minWidth: 300,
    maxWidth: 300
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  avatar: {},
  chartPaper: {
    height: '60vh',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  statsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

interface ChartData {
  [key: string]: number;
}

interface CalendarDataType {
  nbrOfEvents: number;
  nbrOfEventHours: number;
}

interface CalendarData {
  [key: string]: CalendarDataType;
}

const DashboardView: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const { state, dispatch } = useAppContext();
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [calendarData, setCalendarData] = useState<CalendarData | null>(null);

  useEffect(() => {
    const convertData = (
      events: CalendarEvent[],
      calendars: CalendarType[],
      date: Date
    ) => {
      const momentDate = moment(date);
      const tempChartData: ChartData[] = [];
      const tempCalendarData: CalendarData = {};

      for (let i = 1; i <= momentDate.daysInMonth(); i++) {
        const obj: ChartData = { day: i };
        for (const calendar of calendars) {
          obj[calendar.id] = 0;
        }
        // chart data
        tempChartData.push(obj);
      }
      for (const event of events) {
        const startDate = moment(event.start);
        const endDate = moment(event.end);
        // chart data
        if (event.calendar) {
          if (tempChartData[startDate.date() - 1]) {
            tempChartData[startDate.date() - 1][event.calendar.id] += 1;
            // if all-day event add booking count to dates in future
            if (event.allDay) {
              for (let i = 0; i < endDate.diff(startDate, 'days') - 1; i++) {
                tempChartData[startDate.date() + i][event.calendar.id] += 1;
              }
            }
          }

          // box data
          if (!tempCalendarData[event.calendar.id]) {
            tempCalendarData[event.calendar.id] = {
              nbrOfEvents: 0,
              nbrOfEventHours: 0
            };
          }
          tempCalendarData[event.calendar.id].nbrOfEvents += 1;
          tempCalendarData[
            event.calendar.id
          ].nbrOfEventHours += moment
            .duration(endDate.diff(startDate))
            .asHours();
        }
      }
      setChartData(tempChartData);
      setCalendarData(tempCalendarData);
    };
    convertData(state.event.events, state.calendar.calendars, state.app.date);
  }, [state.event.events, state.calendar.calendars, state.app.date]);

  const handleDateChange = (increase: boolean) => {
    let newDate = new Date();
    if (increase) {
      newDate = moment(state.app.date).add(1, 'M').toDate();
    } else {
      newDate = moment(state.app.date).subtract(1, 'M').toDate();
    }
    dispatch(appActions.setDate(newDate));
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Toolbar date={state.app.date} changeDate={handleDateChange} />

      <Container maxWidth={false}>
        <Paper className={classes.chartPaper}>
          <ResponsiveContainer>
            <BarChart
              margin={{ top: 60, left: 0, right: 60, bottom: 30 }}
              data={chartData}
            >
              <XAxis dataKey={'day'} />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              {state.calendar.calendars.map((c: CalendarType) => (
                <Bar
                  key={`calendar-bar-${c.id}`}
                  stackId={'a'}
                  name={c.summary}
                  dataKey={c.id}
                  fill={c.backgroundColor}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Container>

      {calendarData ? (
        <Container className={classes.boxContainer} maxWidth={false}>
          {state.calendar.calendars.map((c: CalendarType) => (
            <Paper className={classes.paper} key={c.calendarId}>
              <Box display="flex" flexDirection="column">
                <Box className={classes.labelBox}>
                  <Typography variant="body1">{c.summary}</Typography>
                  <Avatar
                    alt="calendar-color"
                    variant="circular"
                    className={classes.avatar}
                    style={{
                      color: c.backgroundColor,
                      backgroundColor: c.backgroundColor
                    }}
                  >
                    {c.summary}
                  </Avatar>
                </Box>
                <Box className={classes.statsBox}>
                  <Typography component="div" variant="body1">
                    {'Number of bookings'}
                  </Typography>
                  <Typography component="div" variant="body1">
                    {calendarData[c.id] ? calendarData[c.id].nbrOfEvents : 0}
                  </Typography>
                </Box>
                <Box className={classes.statsBox}>
                  <Typography component="div" variant="body1">
                    {'Number of hours'}
                  </Typography>
                  <Typography component="div" variant="body1">
                    {calendarData[c.id]
                      ? calendarData[c.id].nbrOfEventHours
                      : 0}
                  </Typography>
                </Box>
                <Box className={classes.statsBox}>
                  <Typography component="div" variant="body1">
                    {'Number of available hours'}
                  </Typography>
                  <Typography component="div" variant="body1">
                    {moment(state.app.date).daysInMonth() * 24}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          ))}
        </Container>
      ) : null}
    </Container>
  );
};

export default DashboardView;
