import React, {
  useState,
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { CalendarType, DeviceType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    color: theme.palette.text.secondary
  }
}));

interface ResultsItemProps extends PropsWithChildren<unknown> {
  calendars: CalendarType[];
  devices: DeviceType[];
  editDevice: (device: DeviceType) => void;
  hasAdminRights: boolean;
  removeDevice: (device: DeviceType) => void;
}

const Results: FunctionComponent<ResultsItemProps> = (props) => {
  const classes = useStyles();
  const {
    calendars,
    devices,
    editDevice,
    hasAdminRights,
    removeDevice,
    ...rest
  } = props;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getCalendarById = (calendarId: string) => {
    const resCalendar = calendars.find((c) => c.calendarId === calendarId);
    return resCalendar ? resCalendar : null;
  };

  const isEmpty = (value: string) => {
    return !value || value.trim() === '';
  };

  return devices.length ? (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={500}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Device Logo</TableCell>
                <TableCell>Room name</TableCell>
                <TableCell>Information text</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {devices
                .slice(0, limit)
                .map((device: DeviceType, index: number) => (
                  <TableRow hover={true} key={device.id}>
                    <TableCell>{device.id}</TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          alt="device-logo"
                          variant="circular"
                          className={classes.avatar}
                          src={device.logoURL as string}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        {device.calendarId ? (
                          <Avatar
                            alt="calendar-color"
                            variant="circular"
                            className={classes.avatar}
                            style={{
                              color: getCalendarById(
                                device.calendarId as string
                              )?.backgroundColor,
                              backgroundColor: getCalendarById(
                                device.calendarId as string
                              )?.backgroundColor
                            }}
                          >
                            {
                              getCalendarById(device.calendarId as string)
                                ?.summary
                            }
                          </Avatar>
                        ) : null}
                        <Typography color="textPrimary" variant="body1">
                          {
                            getCalendarById(device.calendarId as string)
                              ?.summary
                          }
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {!isEmpty(device.infoHeader as string) &&
                      !isEmpty(device.infoText as string) ? (
                        <Tooltip
                          title={
                            <>
                              <Typography color="inherit">
                                {device.infoHeader}
                              </Typography>
                              {device.infoText}
                            </>
                          }
                          arrow
                        >
                          <InfoIcon className={classes.icon} />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        onClick={() => editDevice(devices[index])}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        onClick={() => removeDevice(devices[index])}
                        disabled={!hasAdminRights}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={devices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  ) : null;
};

export default Results;
