import React, {
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TeamType } from '../../../types';

interface ToolbarItemProps extends PropsWithChildren<unknown> {
  filterTeams: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  editTeam: (team: TeamType | null) => void;
}

const Toolbar: FunctionComponent<ToolbarItemProps> = (props) => {
  const { filterTeams, editTeam, ...rest } = props;

  return (
    <div {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => editTeam(null)}
        >
          Add team
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search team"
                variant="outlined"
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => filterTeams(e)}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
