import React, { FunctionComponent } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const Copyright: FunctionComponent<unknown> = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cafebabe.se/">
        CAFEBABE AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
export default Copyright;
