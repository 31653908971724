import moment from 'moment';

// import 'moment/locale/sv';

moment.updateLocale('sv', {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 1 // Used to determine first week of the year.
  }
});
// moment.locale('sv');

export default moment;
