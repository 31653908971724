import produce from 'immer';
import { ActionType, AuthActions, AuthState } from './types';

export const initialAuthState: AuthState = {
  user: {
    displayName: null,
    email: null,
    photoURL: null,
    uid: null
  },
  isAuthenticated: false
};

export const authReducer = (
  state: AuthState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case ActionType.SET_USER: {
      return produce(state, (draftState) => {
        draftState.user = action.payload;
      });
    }
    case ActionType.SET_IS_AUTHENTICATED: {
      return produce(state, (draftState) => {
        draftState.isAuthenticated = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};
