export enum ActionType {
  SET_IS_INITIALIZING = 'SET_IS_INITIALIZING',
  SET_DATE = 'SET_DATE'
}

export interface SetIsInitializingAction {
  type: ActionType.SET_IS_INITIALIZING;
  payload: boolean;
}

export interface SetDateAction {
  type: ActionType.SET_DATE;
  payload: Date;
}

export type AppState = {
  date: Date;
  isInitializing: boolean;
};

export type AppActions = SetDateAction | SetIsInitializingAction;
