import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';
import dotenv from 'dotenv';
import { SCOPE_CALENDAR, SCOPE_CALENDAR_EVENTS } from '../constants';

dotenv.config();

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
});

export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();

firebase.analytics();

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope(SCOPE_CALENDAR);
googleProvider.addScope(SCOPE_CALENDAR_EVENTS);

export const signInWithGoogle = async (): Promise<void> => {
  try {
    await auth.signInWithPopup(googleProvider);
  } catch (error) {
    throw new Error(`Firebase signInWithPopup failed with error: ${error}`);
  }
};

export const signInWithCredential = async (idToken: string): Promise<void> => {
  try {
    const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
    await auth.signInWithCredential(credential);
  } catch (error) {
    throw new Error(
      `Firebase signInWithCredential failed with error: ${error}`
    );
  }
};

export const logOut = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (error) {
    throw new Error(`Firebase signOut failed with error: ${error}`);
  }
};

export const getAuthUserDbRef = (
  userUid: string | null
): firebase.database.Reference => {
  return database.ref('/users/' + userUid?.toString());
};

export const getCalendarsDbRef = (
  userUid: string | null
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/calendars');
};

export const getCalendarDbRef = (
  userUid: string | null,
  calendarId: string
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/calendars/' + calendarId);
};

export const getDevicesDbRef = (
  userUid: string | null
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/devices');
};

export const getDeviceDbRef = (
  userUid: string | null,
  deviceId: string
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/devices/' + deviceId);
};

export const getExtrasDbRef = (
  userUid: string | null,
  calendarId: string
): firebase.database.Reference => {
  return database.ref(
    '/users/' + userUid + '/calendars/' + calendarId + '/extras'
  );
};

export const getTeamsDbRef = (
  userUid: string | null
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/teams');
};

export const getTeamDbRef = (
  userUid: string | null,
  teamId: string
): firebase.database.Reference => {
  return database.ref('/users/' + userUid + '/teams/' + teamId);
};

export const getUserDeviceLogosRef = (
  userUid: string | null
): firebase.storage.Reference => {
  return storage.ref('images/' + userUid?.toString() + '/device/');
};

export const getUserTeamLogosRef = (
  userUid: string | null
): firebase.storage.Reference => {
  return storage.ref('images/' + userUid?.toString() + '/team/');
};
