import { ActionType, SetDateAction, SetIsInitializingAction } from './types';

export const setDate = (date: Date): SetDateAction => {
  return {
    type: ActionType.SET_DATE,
    payload: date
  };
};

export const setIsInitializing = (
  isInitializing: boolean
): SetIsInitializingAction => {
  return {
    type: ActionType.SET_IS_INITIALIZING,
    payload: isInitializing
  };
};
