import React, {
  MouseEvent,
  TouchEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useRef,
  useState
} from 'react';
import {
  AppBar,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import produce from 'immer';
import { CalendarType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    flex: 1
  },
  content: {
    padding: theme.spacing(2)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  buttonGroup: {
    alignSelf: 'center',
    padding: theme.spacing(2)
  }
}));

interface EditRoomProps extends PropsWithChildren<unknown> {
  calendar: CalendarType;
  fullScreen: boolean;
  open: boolean;
  closeDialog: () => void;
  saveCalendar: (calendar: CalendarType) => void;
}

const EditRoom = forwardRef((props: EditRoomProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const { calendar, open, fullScreen, closeDialog, saveCalendar } = props;
  const [nbrOfPeople, setNbrOfPeople] = useState<number>(
    calendar.extras.nbrOfPeople
  );
  const [nbrOfShowers, setNbrOfShowers] = useState<number>(
    calendar.extras.nbrOfShowers
  );
  const [nbrOfToilets, setNbrOfToilets] = useState<number>(
    calendar.extras.nbrOfToilets
  );

  // long press functionality
  let counter = 0;
  const timerinterval = useRef<number | null>(null);

  const handlePeopleChange = (decrease: boolean, value?: number) => {
    if (value !== undefined) {
      setNbrOfPeople(value);
    } else {
      let tempNbrOfPeople = nbrOfPeople;
      if (decrease && tempNbrOfPeople > 0) {
        tempNbrOfPeople--;
      } else if (!decrease) {
        tempNbrOfPeople++;
      }
      setNbrOfPeople(tempNbrOfPeople);
    }
  };

  const handleShowersChange = (decrease: boolean, value?: number) => {
    let tempNbrOfShowers = nbrOfShowers;
    if (value !== undefined) {
      setNbrOfShowers(value);
    } else {
      if (decrease && tempNbrOfShowers > 0) {
        tempNbrOfShowers--;
      } else if (!decrease) {
        tempNbrOfShowers++;
      }
      setNbrOfShowers(tempNbrOfShowers);
    }
  };

  const handleToiletsChange = (decrease: boolean, value?: number) => {
    if (value !== undefined) {
      setNbrOfToilets(value);
    } else {
      let tempNbrOfToilets = nbrOfToilets;
      if (decrease && tempNbrOfToilets > 0) {
        tempNbrOfToilets--;
      } else if (!decrease) {
        tempNbrOfToilets++;
      }
      setNbrOfToilets(tempNbrOfToilets);
    }
  };

  const save = () => {
    const updatedCalendar = produce(calendar, (draftState) => {
      draftState.extras = {
        nbrOfPeople,
        nbrOfShowers,
        nbrOfToilets
      };
    });
    saveCalendar(updatedCalendar);
  };

  const timer = (
    start: boolean,
    decrease: boolean,
    changeFunction: (inDecrease: boolean, inCounter: number) => void
  ) => {
    if (start === true) {
      timerinterval.current = window.setInterval(() => {
        if (decrease && counter > 0) {
          counter -= 1;
        } else if (!decrease) {
          counter += 1;
        } else {
          clearInterval(timerinterval.current || 0);
        }
        changeFunction(decrease, counter);
      }, 200);
    }
  };

  const pressingDown = (
    e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>,
    decrease: boolean,
    changeFunction: (inDecrease: boolean, inCounter: number) => void,
    startValue: number
  ) => {
    e.preventDefault();
    clearInterval(timerinterval.current || 0);
    counter = startValue;
    timer(true, decrease, changeFunction);
  };

  const notPressingDown = (
    e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    clearInterval(timerinterval.current || 0);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {calendar?.summary}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {`Edit the changing room's information.`}
        </DialogContentText>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="People"
              secondary="Number of people that the room is appropriate for."
            />
            <ButtonGroup
              className={classes.buttonGroup}
              size="large"
              aria-label="small outlined button group"
            >
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, false, handlePeopleChange, nbrOfPeople)
                }
                onTouchStart={(e) =>
                  pressingDown(e, false, handlePeopleChange, nbrOfPeople)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handlePeopleChange(false)}
              >
                +
              </Button>
              <Button disabled={true}>{nbrOfPeople}</Button>
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, true, handlePeopleChange, nbrOfPeople)
                }
                onTouchStart={(e) =>
                  pressingDown(e, true, handlePeopleChange, nbrOfPeople)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handlePeopleChange(true)}
              >
                -
              </Button>
            </ButtonGroup>
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Showers"
              secondary="Number of showers in the room."
            />
            <ButtonGroup
              className={classes.buttonGroup}
              size="large"
              aria-label="small outlined button group"
            >
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, false, handleShowersChange, nbrOfShowers)
                }
                onTouchStart={(e) =>
                  pressingDown(e, false, handleShowersChange, nbrOfShowers)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handleShowersChange(false)}
              >
                +
              </Button>
              <Button disabled={true}>{nbrOfShowers}</Button>
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, true, handleShowersChange, nbrOfShowers)
                }
                onTouchStart={(e) =>
                  pressingDown(e, true, handleShowersChange, nbrOfShowers)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handleShowersChange(true)}
              >
                -
              </Button>
            </ButtonGroup>
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Toilets"
              secondary="Number of toilets in the room."
            />
            <ButtonGroup
              className={classes.buttonGroup}
              size="large"
              aria-label="small outlined button group"
            >
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, false, handleToiletsChange, nbrOfToilets)
                }
                onTouchStart={(e) =>
                  pressingDown(e, false, handleToiletsChange, nbrOfToilets)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handleToiletsChange(false)}
              >
                +
              </Button>
              <Button disabled={true}>{nbrOfToilets}</Button>
              <Button
                onMouseDown={(e) =>
                  pressingDown(e, true, handleToiletsChange, nbrOfToilets)
                }
                onTouchStart={(e) =>
                  pressingDown(e, true, handleToiletsChange, nbrOfToilets)
                }
                onMouseUp={(e) => notPressingDown(e)}
                onTouchEnd={(e) => notPressingDown(e)}
                onMouseLeave={(e) => notPressingDown(e)}
                onClick={() => handleToiletsChange(true)}
              >
                -
              </Button>
            </ButtonGroup>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="secondary">
          Cancel
        </Button>
        <Button onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EditRoom;
