import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    textDecoration: 'none',
    color: '#727272',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const NotFoundView: FunctionComponent<unknown> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center">
            <RouterLink to="/" className={classes.link}>
              <Typography component="p" variant="body2" align="center">
                Go back to home
              </Typography>
            </RouterLink>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default NotFoundView;
