import produce from 'immer';
import { TeamType } from '../../types';
import { ActionType, TeamActions, TeamState } from './types';

export const initialTeamState: TeamState = {
  teams: [],
  currentEditingTeam: null
};

export const teamReducer = (
  state: TeamState,
  action: TeamActions
): TeamState => {
  switch (action.type) {
    case ActionType.ADD_TEAM: {
      if (!state.teams.find((t: TeamType) => t.id === action.payload.id)) {
        return produce(state, (draftState) => {
          draftState.teams.push(action.payload);
          draftState.teams.sort((t1: TeamType, t2: TeamType) => {
            if (!t1.name || !t2.name) {
              return 0;
            }
            return t1.name.localeCompare(t2.name);
          });
        });
      }
      return state;
    }
    case ActionType.REMOVE_TEAM: {
      return produce(state, (draftState) => {
        draftState.teams = draftState.teams.filter(
          (t: TeamType) => t.id !== action.payload.id
        );
      });
    }
    case ActionType.UPDATE_TEAM: {
      return produce(state, (draftState) => {
        const teamIndex = draftState.teams.findIndex(
          (t: TeamType) => t.id === action.payload.id
        );
        draftState.teams[teamIndex] = action.payload;
        draftState.teams.sort((t1: TeamType, t2: TeamType) => {
          if (!t1.name || !t2.name) {
            return 0;
          }
          return t1.name.localeCompare(t2.name);
        });
      });
    }
    case ActionType.SET_TEAMS: {
      return produce(state, (draftState) => {
        draftState.teams = action.payload;
      });
    }
    case ActionType.SET_CURRENT_EDITING_TEAM: {
      return produce(state, (draftState) => {
        draftState.currentEditingTeam = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};
