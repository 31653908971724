import React, { FunctionComponent, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  Avatar,
  Box,
  Typography,
  CssBaseline,
  Container,
  makeStyles
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GoogleButton from 'react-google-button';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../components/copyright/copyright';
import { useAppContext } from '../../context/app-context';
import * as google from '../../services/google';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Login: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const { state } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      navigate('/app/dashboard');
    }
  }, [state.auth.isAuthenticated, navigate]);

  const signIn = async () => {
    await google.signIn();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          {'Sign In'}
        </Typography>
        <GoogleButton className={classes.submit} onClick={signIn} />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
