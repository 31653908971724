import { CalendarExtras, CalendarType } from '../../types';

export enum ActionType {
  SET_CALENDARS = 'SET_CALENDARS',
  SET_IS_CALENDARS_FETCHED = 'SET_IS_CALENDARS_FETCHED',
  SET_CURRENT_SELECTED_CALENDAR = 'SET_CURRENT_SELECTED_CALENDAR',
  UPDATE_CALENDAR_EXTRAS = 'UPDATE_CALENDAR_EXTRAS'
}

/* Action */
export interface SetCalendarsAction {
  type: ActionType.SET_CALENDARS;
  payload: CalendarType[];
}

export interface SetIsCalendarsFetchedAction {
  type: ActionType.SET_IS_CALENDARS_FETCHED;
  payload: boolean;
}

export interface SetCurrentSelectedCalendarAction {
  type: ActionType.SET_CURRENT_SELECTED_CALENDAR;
  payload: CalendarType | null;
}

export interface UpdateCalendarExtrasAction {
  type: ActionType.UPDATE_CALENDAR_EXTRAS;
  payload: {
    calendarId: string;
    extras: CalendarExtras;
  };
}

export type CalendarState = {
  calendars: CalendarType[];
  isCalendarsFetched: boolean;
  currentSelectedCalendar: CalendarType | null;
};

export type CalendarActions =
  | SetCalendarsAction
  | UpdateCalendarExtrasAction
  | SetIsCalendarsFetchedAction
  | SetCurrentSelectedCalendarAction;
