import React, { useEffect, FunctionComponent } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Favicon from 'react-favicon';
import * as firebase from '../../services/firebase';
import * as google from '../../services/google';
import GlobalStyles from '../../components/globalstyle/globalstyle';
import routes from '../../routes';
import theme from '../../theme';
import { DEFAULT_USER } from '../../constants';
import moment from '../../utils/moment';
import { useAppContext } from '../../context/app-context';
import * as appActions from '../../context/app/actions';
import * as authActions from '../../context/auth/actions';

const App: FunctionComponent<unknown> = () => {
  const { state, dispatch } = useAppContext();
  const routing = useRoutes(routes);

  useEffect(() => {
    // create an scoped async function in the hook
    //    async function initGapiScript() {
    const initGapiScript = async () => {
      const res = await google.init();

      if (await google.isSignedIn()) {
        const currentUser = await google.getCurrentUser();
        // firebase authentication signin
        await firebase.signInWithCredential(
          currentUser.getAuthResponse().id_token
        );
      }

      // handle signedin changes and set signedin
      res.isSignedIn.listen(async (isSignedIn: boolean) => {
        if (!isSignedIn) {
          await firebase.logOut();
        }
      });

      // handle current user changes
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      res.currentUser.listen(async (currentUser: any) => {
        if (currentUser) {
          // firebase authentication signin
          if (currentUser.getAuthResponse().id_token) {
            await firebase.signInWithCredential(
              currentUser.getAuthResponse().id_token
            );
          } else {
            await firebase.logOut();
          }
        }
      });

      // handle firebase authentication state changes
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      firebase.auth.onAuthStateChanged(async (user: any) => {
        dispatch(authActions.setUser(user ? user : DEFAULT_USER));
        dispatch(authActions.setIsAuthenticated(user ? true : false));
        // to handle not showing dashboard for a brief second
        dispatch(appActions.setIsInitializing(false));
      });
    };
    initGapiScript();
  }, [dispatch]);

  const initializingBackground = () => (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img
        style={{ width: '50%', maxWidth: 144 }}
        alt={'logo'}
        src={'/images/digisign-icon-144x144.png'}
      />
    </div>
  );

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <Favicon url={'/images/digisign-icon-144x144.png'} />
        <GlobalStyles />
        {state.app.isInitializing ? initializingBackground() : <>{routing}</>}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
