import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  PropsWithChildren
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none'
  },
  logoButtonContainer: {
    display: 'flex'
  },
  logoUploadButtonWrapper: {
    marginRight: theme.spacing(1),
    position: 'relative'
  },
  logoUploadButtonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  logoPreview: {
    maxHeight: 160,
    width: 'auto',
    maxWidth: '100%',
    marginTop: theme.spacing(2)
  },
  logoMsg: {
    marginTop: theme.spacing(2)
  }
}));

interface LogoListItemProps extends PropsWithChildren<unknown> {
  handleFireBaseUpload: (image: File) => void;
  imageUrlList: string[];
  listItemClass: string;
  logoURL: string;
  setFileSizeAlertOpen: Dispatch<SetStateAction<boolean>>;
  setLogoGridOpen: Dispatch<SetStateAction<boolean>>;
  uploading: boolean;
}

const LogoListItem: FunctionComponent<LogoListItemProps> = (
  props: LogoListItemProps
) => {
  const classes = useStyles();
  const {
    handleFireBaseUpload,
    imageUrlList,
    listItemClass,
    logoURL,
    setFileSizeAlertOpen,
    setLogoGridOpen,
    uploading
  } = props;

  const isEmpty = (value: string | null) => {
    return !value || value.trim() === '';
  };

  const validateSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fsize = selectedFile.size;
      const file = Math.round(fsize / 1024);
      // validate the size of the file.
      if (file >= 1024) {
        setFileSizeAlertOpen(true);
      } else {
        handleFireBaseUpload(selectedFile);
      }
    }
  };

  return (
    <ListItem className={listItemClass}>
      <ListItemText
        primary="Logo *"
        secondary="Upload new och select among avaliable."
      />
      <Box className={classes.logoButtonContainer}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple={true}
          type="file"
          onChange={(e) => validateSelectedFile(e)}
        />
        <label htmlFor="contained-button-file">
          <div className={classes.logoUploadButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={uploading}
            >
              Upload
            </Button>
            {uploading && (
              <CircularProgress
                size={24}
                className={classes.logoUploadButtonProgress}
              />
            )}
          </div>
        </label>
        {imageUrlList.length ? (
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => setLogoGridOpen(true)}
          >
            Select from list
          </Button>
        ) : null}
      </Box>
      {!isEmpty(logoURL) ? (
        <img src={logoURL} className={classes.logoPreview} alt={'logo'} />
      ) : (
        <Typography
          className={classes.logoMsg}
          component={'div'}
          color={'error'}
        >
          <Box fontStyle="italic" m={1}>
            * No logo selected
          </Box>
        </Typography>
      )}
    </ListItem>
  );
};

export default LogoListItem;
