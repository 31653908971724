import React, {
  PropsWithChildren,
  Ref,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import RepeatIcon from '@material-ui/icons/Loop';
import EditIcon from '@material-ui/icons/Edit';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import moment from '../../../utils/moment';
import { rrulestr } from 'rrule';
import { CalendarEvent, TeamType } from '../../../types';
import RecurringEventChanged from './recurring-event-changed';
import DeleteAlertDialog from '../../../components/dialog/delete-alert';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  content: {
    padding: theme.spacing(1)
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start'
  },
  contentRowIcon: {
    width: theme.spacing(4),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  contentRowValue: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(30)
  },
  contentRowInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    marginBottom: theme.spacing(1)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  propertyContainerRepeat: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  repeatIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: 65,
    height: 65,
    borderRadius: '50%',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad'
    }
  }
}));

interface ViewEventProps extends PropsWithChildren<unknown> {
  closeDialog: () => void;
  deleteEvent: (event?: CalendarEvent) => void;
  editEvent: () => void;
  event: CalendarEvent;
  fullScreen: boolean;
  open: boolean;
  recurringEvents: CalendarEvent[];
  teams: TeamType[];
}

const ViewEvent = forwardRef((props: ViewEventProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const {
    closeDialog,
    deleteEvent,
    editEvent,
    event,
    fullScreen,
    open,
    recurringEvents,
    teams
  } = props;
  const [startEndDateString, setStartEndDateString] = useState<string>('');
  const [teamList, setTeamList] = useState<TeamType[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [recurrence, setRecurrence] = useState<string>('');
  const [recurringEvent, setRecurringEvent] = useState<
    CalendarEvent | undefined
  >();
  const [
    recurringEventChangedDialogOpen,
    setRecurringEventChangedDialogOpen
  ] = useState<boolean>(false);

  useEffect(() => {
    // parse team from string
    if (event.description) {
      const parsedTeamListId = JSON.parse(event.description).map(
        (t: TeamType) => t.id
      );
      setTeamList(
        teams.filter((t: TeamType) => parsedTeamListId.includes(t.id))
      );
    }

    // calculate date string
    const momentStart = moment(event.start);
    let momentEnd = moment(event.end);
    if (event.allDay) {
      momentEnd = momentEnd.subtract(1, 'day');
      // handle event date display for all-day event
      if (momentStart.day() === momentEnd.day()) {
        setStartEndDateString(
          `${momentStart.format('D')} ${momentEnd.format('MMMM yyyy')}`
        );
      } else {
        setStartEndDateString(
          `${momentStart.format('D')}${
            momentStart.month() !== momentEnd.month()
              ? ' ' + momentStart.format('MMMM')
              : ''
          }${
            momentStart.year() !== momentEnd.year()
              ? ' ' + momentStart.format('yyyy')
              : ''
          } – ${momentEnd.format('D')} ${momentEnd.format('MMMM yyyy')}`
        );
      }
    } else {
      // handle event date display for single date event
      // den 20 oktober 2020, 8:30am–den 21 oktober 2020, 9:30am
      setStartEndDateString(
        `${momentStart.format('dddd, D MMMM')} ⋅ ${momentStart.format(
          'HH:mm'
        )} – ${
          momentStart.day() < momentEnd.day()
            ? momentEnd.format('dddd, D MMMM') + ' ⋅ '
            : ''
        }${momentEnd.format('HH:mm')}`
      );
    }

    if ((event as CalendarEvent).recurringEventId) {
      const tempRecurringEvent = recurringEvents.find(
        (e: CalendarEvent) => e.id === (event as CalendarEvent).recurringEventId
      );
      if (tempRecurringEvent) {
        setRecurrence(
          tempRecurringEvent.recurrence ? tempRecurringEvent.recurrence[0] : ''
        );
        setRecurringEvent(tempRecurringEvent);
      }
    }
  }, [event, recurringEvents, teams]);

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const openConfirmDialog = () => {
    if (event.recurringEventId) {
      setRecurringEventChangedDialogOpen(true);
    } else {
      setConfirmDialogOpen(true);
    }
  };

  const confirmDelete = () => {
    deleteEvent();
  };

  const handleDeleteEvent = (deleteEventType: string) => {
    if (deleteEventType === 'THIS_EVENT') {
      deleteEvent();
    } else {
      deleteEvent(recurringEvent);
    }
  };

  const closeRecurringEventDialog = () => {
    setRecurringEventChangedDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <DialogActions>
        <Button className={classes.button} onClick={() => editEvent()}>
          <EditIcon />
        </Button>
        <Button className={classes.button} onClick={openConfirmDialog}>
          <DeleteIcon />
        </Button>
        <Button className={classes.button} onClick={() => closeDialog()}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent className={classes.content}>
        <List>
          <ListItem>
            <Box className={classes.contentRow}>
              <Box className={classes.contentRowIcon}>
                <Avatar
                  alt="calendar-color"
                  variant="circular"
                  className={classes.avatar}
                  style={{
                    color: event.calendar?.backgroundColor,
                    backgroundColor: event.calendar?.backgroundColor
                  }}
                >
                  {event.calendar?.summary}
                </Avatar>
              </Box>
              <Box className={classes.contentRowValue}>
                <Typography component="div" variant="body1">
                  {event.title}
                </Typography>
                <Typography component="div" variant="body2">
                  {startEndDateString}
                </Typography>
                {recurrence !== '' ? (
                  <Box className={classes.propertyContainerRepeat}>
                    <RepeatIcon
                      className={classes.repeatIcon}
                      fontSize={'inherit'}
                    />
                    <Typography component="div" variant="body2">
                      {rrulestr(recurrence).toText()}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Box className={classes.contentRow}>
              <Box className={classes.contentRowIcon}>
                <UsersIcon />
              </Box>
              <Box className={classes.contentRowValue}>
                {teamList.map((t: TeamType) => (
                  <Box
                    key={`team-name-${t.id}`}
                    className={classes.contentRowInner}
                  >
                    <Avatar
                      alt="team-logo"
                      variant="circular"
                      className={classes.avatar}
                      src={t.logoURL as string}
                    >
                      {t.name}
                    </Avatar>
                    <Typography component="div" variant="body1">
                      {t.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Box className={classes.contentRow}>
              <Box className={classes.contentRowIcon}>
                <CalendarIcon />
              </Box>
              <Box className={classes.contentRowValue}>
                <Typography component="div" variant="body1">
                  {event.calendar?.summary}
                </Typography>
                <Typography component="div" variant="body2">
                  {`Created by: ${
                    event.creator.displayName
                      ? event.creator.displayName
                      : event.creator.email
                  }`}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        </List>
      </DialogContent>
      {confirmDialogOpen ? (
        <DeleteAlertDialog
          open={confirmDialogOpen}
          fullScreen={fullScreen}
          handleClose={closeConfirmDialog}
          confirmDelete={confirmDelete}
          dialogContent={`Are you sure you want to delete event: "${event.title}"?`}
        />
      ) : null}
      {recurringEventChangedDialogOpen ? (
        <RecurringEventChanged
          open={recurringEventChangedDialogOpen}
          fullScreen={fullScreen}
          closeDialog={closeRecurringEventDialog}
          updateAction={handleDeleteEvent}
          type={'DELETE'}
          recurrenceHasChanged={false}
        />
      ) : null}
    </Dialog>
  );
});

export default ViewEvent;
