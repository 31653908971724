import { DeviceType } from '../../types';
import {
  ActionType,
  AddDeviceAction,
  RemoveDeviceAction,
  SetCurrentEditingDeviceAction,
  SetDevicesAction,
  UpdateDeviceAction
} from './types';

export const addDevice = (device: DeviceType): AddDeviceAction => {
  return {
    type: ActionType.ADD_DEVICE,
    payload: device
  };
};

export const updateDevice = (device: DeviceType): UpdateDeviceAction => {
  return {
    type: ActionType.UPDATE_DEVICE,
    payload: device
  };
};

export const removeDevice = (device: DeviceType): RemoveDeviceAction => {
  return {
    type: ActionType.REMOVE_DEVICE,
    payload: device
  };
};

export const setCurrentEditingDevice = (
  device: DeviceType | null
): SetCurrentEditingDeviceAction => {
  return {
    type: ActionType.SET_CURRENT_EDITING_DEVICE,
    payload: device
  };
};

export const setDevices = (devices: DeviceType[]): SetDevicesAction => {
  return {
    type: ActionType.SET_DEVICES,
    payload: devices
  };
};
