import React, { FunctionComponent, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import NavBar from '../../components/navbar/navbar';
import TopBar from '../../components/topbar/topbar';
import * as firebase from '../../services/firebase';
import * as google from '../../services/google';
import {
  CalendarEvent,
  CalendarExtras,
  CalendarType,
  DeviceType,
  RecurringEventFetchType,
  TeamType
} from '../../types';
import moment from '../../utils/moment';
import { getDarkBackground } from '../../utils/colors';
import { useAppContext } from '../../context/app-context';
import * as calendarActions from '../../context/calendar/actions';
import * as deviceActions from '../../context/device/actions';
import * as eventsActions from '../../context/event/actions';
import * as teamActions from '../../context/team/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.auth.isAuthenticated) {
      dispatch(calendarActions.setCalendars([]));
      navigate('/login');
    } else {
      // get calendars if logged in
      const fetchCalendars = async () => {
        const calendars = await google.fetchCalendarList();
        calendars.sort((c1: CalendarType, c2: CalendarType) =>
          c1.summary.localeCompare(c2.summary)
        );

        // get initial calendar values from database
        for (const calendar of calendars) {
          await firebase
            .getCalendarDbRef(state.auth.user.uid, calendar.id)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .once('value', (data: any) => {
              const storedValues = data.val();
              if (storedValues != null) {
                // extras
                calendar.extras = storedValues.extras;
              }
            });
        }

        dispatch(calendarActions.setCalendars(calendars));
        dispatch(calendarActions.setIsCalendarsFetched(true));
      };
      fetchCalendars();
    }
  }, [state.auth.user, state.auth.isAuthenticated, navigate, dispatch]);

  useEffect(() => {
    const updateCalendarWithDatabaseValues = (
      calendarId: string,
      extras: CalendarExtras
    ) => {
      dispatch(calendarActions.updateCalendarExtras(calendarId, extras));
    };

    if (state.calendar.isCalendarsFetched) {
      firebase
        .getCalendarsDbRef(state.auth.user.uid)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .on('child_added', (snapshot: any) => {
          const calendarId: string = snapshot.key;
          const newValues: CalendarExtras = snapshot.val().extras;
          updateCalendarWithDatabaseValues(calendarId, newValues);
        });
      firebase
        .getCalendarsDbRef(state.auth.user.uid)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .on('child_changed', (snapshot: any) => {
          const calendarId: string = snapshot.key;
          const newValues: CalendarExtras = snapshot.val().extras;
          updateCalendarWithDatabaseValues(calendarId, newValues);
        });
    }
  }, [state.auth.user, state.calendar.isCalendarsFetched, dispatch]);

  useEffect(() => {
    const fetchAllEvents = async (calendars: CalendarType[], date: Date) => {
      const momentDate = moment(date);
      const start = momentDate.clone().startOf('month').startOf('week');
      const end = momentDate.clone().endOf('month').endOf('week');

      const events = await google.fetchEvents(calendars, start, end);

      // gather all recurring event ids and fetch
      const recurringEventFetchObjs: RecurringEventFetchType[] = events
        .filter((e: CalendarEvent) => e.recurringEventId !== undefined)
        .map((e: CalendarEvent) => ({
          id: e.recurringEventId,
          calendarId: e.calendar?.calendarId
        }))
        .filter(
          (value, index, self) =>
            self.findIndex((obj) => obj.id === value.id) === index
        );
      let recurringEvents: CalendarEvent[] = [];
      if (recurringEventFetchObjs.length) {
        recurringEvents = await google.fetchRecurringEvents(
          recurringEventFetchObjs,
          calendars
        );
      }
      dispatch(eventsActions.setEvents(events));
      dispatch(eventsActions.setRecurringEvents(recurringEvents));
    };

    if (state.calendar.isCalendarsFetched) {
      fetchAllEvents(state.calendar.calendars, state.app.date);
    }
  }, [state.app.date, state.calendar.isCalendarsFetched, dispatch]);

  useEffect(() => {
    const addTeamWithDatabaseValues = (team: TeamType) => {
      dispatch(teamActions.addTeam(team));
    };

    const updateTeamWithDatabaseValues = (team: TeamType) => {
      dispatch(teamActions.updateTeam(team));
    };

    const removeTeamWithDatabaseValues = (team: TeamType) => {
      dispatch(teamActions.removeTeam(team));
    };

    // add database change/added/remove team listeners
    firebase
      .getTeamsDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_added', (snapshot: any) => {
        const newValues = snapshot.val();
        addTeamWithDatabaseValues(newValues);
      });
    firebase
      .getTeamsDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_changed', (snapshot: any) => {
        const newValues = snapshot.val();
        updateTeamWithDatabaseValues(newValues);
      });
    firebase
      .getTeamsDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_removed', (snapshot: any) => {
        const newValues = snapshot.val();
        removeTeamWithDatabaseValues(newValues);
      });
  }, [state.auth.user, dispatch]);

  useEffect(() => {
    const addDeviceWithDatabaseValues = (device: DeviceType) => {
      dispatch(deviceActions.addDevice(device));
    };

    const updateDeviceWithDatabaseValues = (device: DeviceType) => {
      dispatch(deviceActions.updateDevice(device));
    };

    const removeDeviceWithDatabaseValues = (device: DeviceType) => {
      dispatch(deviceActions.removeDevice(device));
    };

    // add database change/added/remove device listeners
    firebase
      .getDevicesDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_added', (snapshot: any) => {
        const newValues = snapshot.val();
        addDeviceWithDatabaseValues(newValues);
      });
    firebase
      .getDevicesDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_changed', (snapshot: any) => {
        const newValues = snapshot.val();
        updateDeviceWithDatabaseValues(newValues);
      });
    firebase
      .getDevicesDbRef(state.auth.user.uid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .on('child_removed', (snapshot: any) => {
        const newValues = snapshot.val();
        removeDeviceWithDatabaseValues(newValues);
      });
  }, [state.auth.user, dispatch]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
