import { CalendarEvent } from '../../types';

export enum ActionType {
  SET_EVENTS = 'SET_EVENTS',
  ADD_EVENT = 'ADD_EVENT',
  REMOVE_EVENT = 'REMOVE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  SET_RECURRING_EVENTS = 'SET_RECURRING_EVENTS',
  ADD_RECURRING_EVENT = 'ADD_RECURRING_EVENT',
  REMOVE_RECURRING_EVENT = 'REMOVE_RECURRING_EVENT',
  UPDATE_RECURRING_EVENTS = 'UPDATE_RECURRING_EVENTS',
  UPDATE_RECURRING_EVENT = 'UPDATE_RECURRING_EVENT'
}

export interface SetEventsAction {
  type: ActionType.SET_EVENTS;
  payload: CalendarEvent[];
}

export interface AddEventAction {
  type: ActionType.ADD_EVENT;
  payload: CalendarEvent;
}

export interface RemoveEventAction {
  type: ActionType.REMOVE_EVENT;
  payload: CalendarEvent;
}

export interface UpdateEventAction {
  type: ActionType.UPDATE_EVENT;
  payload: CalendarEvent;
}

export interface UpdateRecurringEventsAction {
  type: ActionType.UPDATE_RECURRING_EVENTS;
  payload: {
    recurringEventId: string;
    events: CalendarEvent[];
  };
}

export interface SetRecurringEventsAction {
  type: ActionType.SET_RECURRING_EVENTS;
  payload: CalendarEvent[];
}

export interface AddRecurringEventAction {
  type: ActionType.ADD_RECURRING_EVENT;
  payload: CalendarEvent;
}

export interface RemoveRecurringEventAction {
  type: ActionType.REMOVE_RECURRING_EVENT;
  payload: CalendarEvent;
}

export interface UpdateRecurringEventAction {
  type: ActionType.UPDATE_RECURRING_EVENT;
  payload: CalendarEvent;
}

export type EventState = {
  events: CalendarEvent[];
  recurringEvents: CalendarEvent[];
};
export type EventActions =
  | SetEventsAction
  | AddEventAction
  | RemoveEventAction
  | UpdateEventAction
  | UpdateRecurringEventsAction
  | RemoveRecurringEventAction
  | SetRecurringEventsAction
  | AddRecurringEventAction
  | UpdateRecurringEventAction;
