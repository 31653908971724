import React, { PropsWithChildren, forwardRef, MouseEvent, Ref } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  content: {
    padding: theme.spacing(2)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  textField: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gridGap: theme.spacing(1),
    alignItems: 'center'
  },
  logoImg: {
    height: 'auto',
    width: '100%',
    cursor: 'pointer',
    verticalAlign: 'middle'
  }
}));

interface LogoGridProps extends PropsWithChildren<unknown> {
  fullScreen: boolean;
  open: boolean;
  closeDialog: () => void;
  selectLogo: (logoUrl: string) => void;
  imageUrlList: string[];
}

const LogoGrid = forwardRef((props: LogoGridProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const { open, fullScreen, closeDialog, selectLogo, imageUrlList } = props;

  const handleSelectLogo = (e: MouseEvent<HTMLImageElement>) => {
    selectLogo((e.target as HTMLImageElement).currentSrc);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'md'}
      fullWidth={true}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {'Logo URL'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {'Select team logo form the list below.'}
        </DialogContentText>
        <div className={classes.gridContainer}>
          {imageUrlList.map((imageUrl, index) => (
            <img
              className={classes.logoImg}
              key={`logo-image-${index}`}
              src={imageUrl}
              alt={imageUrl}
              onClick={(e) => handleSelectLogo(e)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} onClick={() => closeDialog()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default LogoGrid;
