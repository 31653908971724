export type RecurringEventActionType = 'DELETE' | 'EDIT';

export enum RecurringEventSaveActionEnum {
  ALL_EVENTS = 'ALL_EVENTS',
  THIS_EVENT = 'THIS_EVENT'
}

export interface User {
  uid: string | null;
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
}

export interface CalendarType {
  backgroundColor: string;
  etag: string;
  foregroundColor: string;
  calendarId: string;
  id: string;
  kind: string;
  summary: string;
  timeZone: string;
  extras: CalendarExtras;
}

export interface TeamType {
  id: string | null;
  name: string | null;
  description: string | null;
  logoURL: string | null;
}

export interface DeviceType {
  id: string | null;
  calendarId: string | null;
  calendarShortId: string | null;
  infoHeader: string | null;
  infoText: string | null;
  logoURL: string | null;
  roomName: string | null;
}

export interface CalendarExtras {
  nbrOfPeople: number;
  nbrOfShowers: number;
  nbrOfToilets: number;
}

export interface NewCalendarEvent {
  start: Date;
  end: Date;
  title: string;
  allDay: boolean;
  description: string;
  calendar: CalendarType | null;
  recurrence: string[] | undefined;
}

export interface CalendarEvent extends NewCalendarEvent {
  id: string;
  creator: {
    displayName: string;
    email: string;
  };
  created: Date;
  updated: Date;
  recurringEventId: string | undefined;
  originalStartTime:
    | undefined
    | {
        date: string | undefined;
        dateTime: string;
        timeZone: string;
      };
}

export interface RecurringEventFetchType {
  id: string | undefined;
  calendarId: string | undefined;
}
