import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard-layout/dashboard-layout';
import MainLayout from './layouts/main-layout/main-layout';
import BookingView from './views/settings/booking-view';
import DashboardView from './views/reports/dashboard-view';
import DevicesView from './views/settings/device-view';
import LoginView from './views/auth/login-view';
import NotFoundView from './views/errors/not-found-view';
import RoomsView from './views/settings/rooms-view';
import TeamsView from './views/settings/teams-view';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'booking', element: <BookingView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'devices', element: <DevicesView /> },
      { path: 'rooms', element: <RoomsView /> },
      { path: 'teams', element: <TeamsView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
