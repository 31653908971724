import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RepeatIcon from '@material-ui/icons/Loop';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import './react-big-calendar.css';
import { useAppContext } from '../../../context/app-context';
import * as appActions from '../../../context/app/actions';
import * as eventActions from '../../../context/event/actions';
import EditEvent from './edit-event';
import ViewEvent from './view-event';
import { CalendarType, CalendarEvent, NewCalendarEvent } from '../../../types';
import * as google from '../../../services/google';
import moment from '../../../utils/moment';
import { getDarkBackground } from '../../../utils/colors';
import CalendarToolbar from './calendar-toolbar';

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#767676'
  },
  formControlToolbar: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  calendarPaper: {
    padding: theme.spacing(1)
  }
}));

const eventStyleGetter = (event: CalendarEvent) => {
  const style = {
    backgroundColor: event.calendar?.backgroundColor,
    color: event.calendar?.foregroundColor
  };
  return {
    style
  };
};

const EventWithRecurringIcon = (event: {
  title: string;
  event: CalendarEvent;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {event.event.recurringEventId ? (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
          <RepeatIcon fontSize={'inherit'} />
        </div>
      ) : null}
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {event.title}
      </div>
    </div>
  );
};

const BookingView: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useAppContext();
  const [currentDate, setCurrentDate] = useState<Date>(state.app.date);
  const [currentEvent, setCurrentEvent] = useState<
    CalendarEvent | NewCalendarEvent | null
  >(null);
  const [editEventOpen, setEditEventOpen] = useState(false);
  const [viewEventOpen, setViewEventOpen] = useState(false);
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
  const [selectedCalendarEvents, setSelectedCalendarEvents] = useState<
    CalendarEvent[]
  >([]);

  useEffect(() => {
    const calendarIds = state.calendar.calendars.map(
      (c: CalendarType) => c.calendarId
    );
    setSelectedCalendarIds(calendarIds);
  }, [state.calendar.calendars]);

  useEffect(() => {
    setSelectedCalendarEvents(state.event.events);
  }, [state.event.events]);

  useEffect(() => {
    setCurrentDate(state.app.date);
  }, [state.app.date]);

  const fetchEventsAfterRecurringSave = async (
    calendars: CalendarType[],
    date: Date,
    recurringEventId: string
  ) => {
    const momentDate = moment(date);
    const start = momentDate.clone().startOf('month').startOf('week');
    const end = momentDate.clone().endOf('month').endOf('week');

    let events = await google.fetchEvents(calendars, start, end);
    events = events.filter(
      (e: CalendarEvent) => e.recurringEventId === recurringEventId
    );

    dispatch(eventActions.updateRecurringEvents(recurringEventId, events));
  };

  const handleDeleteEvent = async (
    recurringEvent: CalendarEvent | undefined
  ) => {
    if (recurringEvent) {
      // google remove
      await google.deleteEvent(recurringEvent);
      dispatch(eventActions.removeRecurringEvent(recurringEvent));
    } else {
      await google.deleteEvent(currentEvent as CalendarEvent);
      dispatch(eventActions.removeEvent(currentEvent as CalendarEvent));
    }

    setCurrentEvent(null);
    setViewEventOpen(false);
  };

  const handleEditEvent = () => {
    setViewEventOpen(false);
    setEditEventOpen(true);
  };

  const handleSelectEvent = (event: CalendarEvent) => {
    setCurrentEvent(event);
    setViewEventOpen(true);
  };

  const handleSelectSlot = (slotInfo: {
    end: Date | string;
    start: Date | string;
    slots: string[] | Date[];
    action: string;
  }) => {
    const newEvent: NewCalendarEvent = {
      title: '',
      calendar: null,
      start: slotInfo.start as Date,
      end: slotInfo.end as Date,
      allDay: false,
      description: '',
      recurrence: undefined
    };

    setCurrentEvent(newEvent);
    setEditEventOpen(true);
  };

  const handleNavigate = (date: Date) => {
    const momentDate = moment(date);
    const start = momentDate.clone().startOf('month').startOf('week');
    const end = momentDate.clone().endOf('month').endOf('week');
    const momentStateDate = moment(state.app.date);
    const stateStart = momentStateDate.clone().startOf('month').startOf('week');
    const stateEnd = momentStateDate.clone().endOf('month').endOf('week');
    if (
      start < stateStart ||
      end < stateEnd ||
      start > stateStart ||
      end > stateEnd
    ) {
      dispatch(appActions.setDate(date));
    } else {
      setCurrentDate(date);
    }
  };

  const handleCheckCalendar = (
    event: React.ChangeEvent<HTMLInputElement>,
    calendar: CalendarType
  ) => {
    let tempCalendarIds: string[] = [];
    if (selectedCalendarIds.includes(calendar.calendarId)) {
      tempCalendarIds = selectedCalendarIds.filter(
        (calendarId: string) => calendarId !== calendar.calendarId
      );
    } else {
      tempCalendarIds = [...selectedCalendarIds];
      tempCalendarIds.push(calendar.calendarId);
    }
    setSelectedCalendarIds(tempCalendarIds);
    setSelectedCalendarEvents(
      state.event.events.filter(
        (calendarEvent: CalendarEvent) =>
          calendarEvent.calendar &&
          tempCalendarIds.includes(calendarEvent.calendar.calendarId)
      )
    );
  };

  const closeEditEventDialog = () => {
    setCurrentEvent(null);
    setEditEventOpen(false);
  };

  const saveEvent = async (
    update: boolean,
    event: CalendarEvent | NewCalendarEvent,
    previousCalendar: CalendarType | null
  ) => {
    if (update) {
      const updatedEvent = await google.updateEvent(
        event as CalendarEvent,
        previousCalendar,
        state.calendar.calendars
      );
      if (updatedEvent.recurrence && updatedEvent.calendar !== null) {
        dispatch(eventActions.updateRecurringEvent(updatedEvent));
        // reload all calendar events, then update state
        fetchEventsAfterRecurringSave(
          [updatedEvent.calendar],
          currentDate,
          updatedEvent.id
        );
      } else {
        dispatch(eventActions.updateEvent(updatedEvent));
      }
    } else {
      const newEvent = await google.createEvent(
        event,
        state.calendar.calendars
      );
      if (newEvent.recurrence && newEvent.calendar !== null) {
        dispatch(eventActions.addRecurringEvent(newEvent));
        // reload all calendar events, then update state
        fetchEventsAfterRecurringSave(
          [newEvent.calendar],
          currentDate,
          newEvent.id
        );
      } else {
        dispatch(eventActions.addEvent(newEvent));
      }
    }
    setCurrentEvent(null);
    setEditEventOpen(false);
  };

  const closeViewEventDialog = () => {
    setCurrentEvent(null);
    setViewEventOpen(false);
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Paper className={classes.formControlToolbar}>
        {state.calendar.calendars.map((c: CalendarType) => (
          <FormControlLabel
            key={`label-${c.calendarId}`}
            control={
              <Checkbox
                key={`checkbox-${c.calendarId}`}
                style={{ color: c.backgroundColor }}
                onChange={(e) => handleCheckCalendar(e, c)}
                checked={selectedCalendarIds.includes(c.calendarId)}
              />
            }
            label={c.summary}
          />
        ))}
      </Paper>
      <Paper
        className={classes.calendarPaper}
        style={{ height: !fullScreen ? '81vh' : '76vh' }}
      >
        <Calendar
          selectable={true}
          localizer={localizer}
          events={selectedCalendarEvents}
          startAccessor={'start'}
          endAccessor={'end'}
          titleAccessor={'title'}
          allDayAccessor={'allDay'}
          views={['month', 'week', 'day']}
          /*
            step={5}
            timeslots={3}
            min={new Date(2008, 0, 1, 8, 0)} // 8.00 AM
            max={new Date(2008, 0, 1, 17, 0)} // Max will be 6.00 PM!
          */
          components={{
            toolbar: CalendarToolbar,
            event: EventWithRecurringIcon
          }}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event: CalendarEvent) => handleSelectEvent(event)}
          onSelectSlot={handleSelectSlot}
          onNavigate={handleNavigate}
          date={currentDate}
        />
      </Paper>
      {currentEvent && viewEventOpen ? (
        <ViewEvent
          closeDialog={closeViewEventDialog}
          deleteEvent={handleDeleteEvent}
          editEvent={handleEditEvent}
          event={currentEvent as CalendarEvent}
          fullScreen={fullScreen}
          open={viewEventOpen}
          recurringEvents={state.event.recurringEvents}
          teams={state.team.teams}
        />
      ) : null}
      {currentEvent && editEventOpen ? (
        <EditEvent
          calendars={state.calendar.calendars}
          closeDialog={closeEditEventDialog}
          event={currentEvent}
          recurringEvents={state.event.recurringEvents}
          fullScreen={fullScreen}
          open={editEventOpen}
          saveEvent={saveEvent}
          teams={state.team.teams}
        />
      ) : null}
    </Container>
  );
};

export default BookingView;
