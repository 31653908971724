import produce from 'immer';
import { CalendarEvent } from '../../types';
import { ActionType, EventActions, EventState } from './types';

export const initialEventState: EventState = {
  events: [],
  recurringEvents: []
};

export const eventReducer = (
  state: EventState,
  action: EventActions
): EventState => {
  switch (action.type) {
    case ActionType.SET_EVENTS: {
      return produce(state, (draftState) => {
        draftState.events = action.payload;
      });
    }
    case ActionType.REMOVE_EVENT: {
      return produce(state, (draftState) => {
        draftState.events = draftState.events.filter(
          (e: CalendarEvent) => e.id !== action.payload.id
        );
      });
    }
    case ActionType.UPDATE_EVENT: {
      return produce(state, (draftState) => {
        const eventIndex = draftState.events.findIndex(
          (e: CalendarEvent) => e.id === action.payload.id
        );
        draftState.events[eventIndex] = action.payload;
      });
    }
    case ActionType.UPDATE_RECURRING_EVENTS: {
      return produce(state, (draftState) => {
        draftState.events = draftState.events
          .filter(
            (e: CalendarEvent) =>
              e.recurringEventId !== action.payload.recurringEventId
          )
          .concat(action.payload.events);
      });
    }
    case ActionType.REMOVE_RECURRING_EVENT: {
      return produce(state, (draftState) => {
        draftState.events = draftState.events.filter(
          (e: CalendarEvent) => e.recurringEventId !== action.payload.id
        );
      });
    }
    case ActionType.ADD_EVENT: {
      return produce(state, (draftState) => {
        draftState.events.push(action.payload);
      });
    }
    case ActionType.SET_RECURRING_EVENTS: {
      return produce(state, (draftState) => {
        draftState.recurringEvents = action.payload;
      });
    }
    case ActionType.UPDATE_RECURRING_EVENT: {
      return produce(state, (draftState) => {
        const eventIndex = draftState.recurringEvents.findIndex(
          (e: CalendarEvent) => e.id === action.payload.id
        );
        draftState.recurringEvents[eventIndex] = action.payload;
      });
    }
    case ActionType.ADD_RECURRING_EVENT: {
      return produce(state, (draftState) => {
        draftState.recurringEvents.push(action.payload);
      });
    }
    default: {
      return state;
    }
  }
};
