import { CalendarExtras, CalendarType } from '../../types';
import {
  ActionType,
  SetCalendarsAction,
  SetCurrentSelectedCalendarAction,
  SetIsCalendarsFetchedAction,
  UpdateCalendarExtrasAction
} from './types';

export const setCalendars = (calendars: CalendarType[]): SetCalendarsAction => {
  return {
    type: ActionType.SET_CALENDARS,
    payload: calendars
  };
};

export const setCurrentSelectedCalendar = (
  calendar: CalendarType | null
): SetCurrentSelectedCalendarAction => {
  return {
    type: ActionType.SET_CURRENT_SELECTED_CALENDAR,
    payload: calendar
  };
};

export const setIsCalendarsFetched = (
  isCalendarsFetched: boolean
): SetIsCalendarsFetchedAction => {
  return {
    type: ActionType.SET_IS_CALENDARS_FETCHED,
    payload: isCalendarsFetched
  };
};

export const updateCalendarExtras = (
  calendarId: string,
  extras: CalendarExtras
): UpdateCalendarExtrasAction => {
  return {
    type: ActionType.UPDATE_CALENDAR_EXTRAS,
    payload: { calendarId, extras }
  };
};
