import { TeamType } from '../../types';
import {
  ActionType,
  AddTeamAction,
  RemoveTeamAction,
  SetCurrentEditingTeamAction,
  SetTeamsAction,
  UpdateTeamAction
} from './types';

export const addTeam = (team: TeamType): AddTeamAction => {
  return {
    type: ActionType.ADD_TEAM,
    payload: team
  };
};

export const updateTeam = (team: TeamType): UpdateTeamAction => {
  return {
    type: ActionType.UPDATE_TEAM,
    payload: team
  };
};

export const removeTeam = (team: TeamType): RemoveTeamAction => {
  return {
    type: ActionType.REMOVE_TEAM,
    payload: team
  };
};

export const setCurrentEditingTeam = (
  team: TeamType | null
): SetCurrentEditingTeamAction => {
  return {
    type: ActionType.SET_CURRENT_EDITING_TEAM,
    payload: team
  };
};

export const setTeams = (teams: TeamType[]): SetTeamsAction => {
  return {
    type: ActionType.SET_TEAMS,
    payload: teams
  };
};
