import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect
} from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useAppContext } from '../../../context/app-context';
import * as teamActions from '../../../context/team/actions';
import { TeamType } from '../../../types';
import EditTeam from './edit-team';
import Results from './results';
import Toolbar from './toolbar';
import * as firebase from '../../../services/firebase';
import { DEFAULT_TEAM } from '../../../constants';
import { getDarkBackground } from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TeamListView: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useAppContext();
  const [filteredTeams, setFilteredTeams] = useState(state.team.teams);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFilteredTeams(state.team.teams);
  }, [state.team.teams]);

  const filterTeams = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilteredTeams(
      state.team.teams.filter((t: TeamType) =>
        t.name?.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  const closeDialog = () => {
    setOpen(false);
    dispatch(teamActions.setCurrentEditingTeam(null));
  };

  const saveTeam = async (team: TeamType) => {
    setOpen(false);
    if (team) {
      const teamsRef = firebase.getTeamsDbRef(state.auth.user.uid);
      if (!team.id) {
        const newTeamId = await teamsRef.push().key;
        team.id = newTeamId;
      }
      if (team.id) {
        const teamRef = firebase.getTeamDbRef(state.auth.user.uid, team.id);
        teamRef.set(team);
        dispatch(teamActions.setCurrentEditingTeam(null));
      }
    }
  };

  const editTeam = (team: TeamType | null) => {
    setOpen(true);
    const editingTeam = team ? team : DEFAULT_TEAM;
    dispatch(teamActions.setCurrentEditingTeam(editingTeam));
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Toolbar filterTeams={filterTeams} editTeam={editTeam} />
      <Box mt={3}>
        <Results editTeam={editTeam} teams={filteredTeams} />
      </Box>
      {state.team.currentEditingTeam ? (
        <EditTeam
          open={open}
          fullScreen={fullScreen}
          closeDialog={closeDialog}
          saveTeam={saveTeam}
          team={state.team.currentEditingTeam}
          user={state.auth.user}
        />
      ) : null}
    </Container>
  );
};

export default TeamListView;
