import React, {
  useState,
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import EditIcon from '@material-ui/icons/Edit';
import { CalendarType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  }
}));

interface ResultsItemProps extends PropsWithChildren<unknown> {
  calendars: CalendarType[];
  editCalendarExtras: (calendar: CalendarType) => void;
}

const Results: FunctionComponent<ResultsItemProps> = (props) => {
  const classes = useStyles();
  const { calendars, editCalendarExtras, ...rest } = props;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return calendars.length ? (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={500}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>People</TableCell>
                <TableCell>Showers</TableCell>
                <TableCell>Toilets</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {calendars
                .slice(0, limit)
                .map((calendar: CalendarType, index: number) => (
                  <TableRow hover={true} key={calendar.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          alt="calendar-color"
                          variant="circular"
                          className={classes.avatar}
                          style={{
                            color: calendar.backgroundColor,
                            backgroundColor: calendar.backgroundColor
                          }}
                        >
                          {calendar.summary}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {calendar.summary}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{calendar.extras.nbrOfPeople}</TableCell>
                    <TableCell>{calendar.extras.nbrOfShowers}</TableCell>
                    <TableCell>{calendar.extras.nbrOfToilets}</TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        onClick={() => editCalendarExtras(calendars[index])}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={calendars.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  ) : null;
};

export default Results;
