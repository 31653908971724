import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect
} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useAppContext } from '../../../context/app-context';
import * as deviceActions from '../../../context/device/actions';
import { DeviceType } from '../../../types';
import EditDevice from './edit-device';
import DeleteAlertDialog from '../../../components/dialog/delete-alert';
import Results from './results';
import Toolbar from './toolbar';
import * as firebase from '../../../services/firebase';
import { DEFAULT_DEVICE } from '../../../constants';
import { getDarkBackground } from '../../../utils/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: getDarkBackground(),
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeviceListView: FunctionComponent<unknown> = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useAppContext();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [deviceToBeRemoved, setDeviceToBeRemoved] = useState<DeviceType | null>(
    null
  );
  const [filteredDevices, setFilteredDevices] = useState(state.device.devices);
  const [open, setOpen] = useState(false);
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false);

  useEffect(() => {
    setHasAdminRights(
      new URLSearchParams(location.search).get('secret') ===
        process.env.REACT_APP_DEVICE_ADMIN_SECRET
    );
  }, [location]);

  useEffect(() => {
    setFilteredDevices(state.device.devices);
  }, [state.device.devices]);

  const filterDevices = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilteredDevices(
      state.device.devices.filter((d: DeviceType) =>
        d.id?.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  const closeDialog = () => {
    setOpen(false);
    dispatch(deviceActions.setCurrentEditingDevice(null));
  };

  const saveDevice = async (device: DeviceType) => {
    setOpen(false);
    if (device) {
      const devicesRef = firebase.getDevicesDbRef(state.auth.user.uid);
      if (!device.id) {
        const newDeviceId = await devicesRef.push().key;
        device.id = newDeviceId;
      }
      if (device.id) {
        const deviceRef = firebase.getDeviceDbRef(
          state.auth.user.uid,
          device.id
        );
        deviceRef.set(device);
        dispatch(deviceActions.setCurrentEditingDevice(null));
      }
    }
  };

  const editDevice = (device: DeviceType | null) => {
    setOpen(true);
    const editingDevice = device ? device : DEFAULT_DEVICE;
    dispatch(deviceActions.setCurrentEditingDevice(editingDevice));
  };

  const removeDevice = async () => {
    setConfirmDialogOpen(false);
    if (deviceToBeRemoved) {
      const deviceRef = firebase.getDeviceDbRef(
        state.auth.user.uid,
        deviceToBeRemoved.id as string
      );
      await deviceRef.remove();
      dispatch(deviceActions.removeDevice(deviceToBeRemoved));
      setDeviceToBeRemoved(null);
    }
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const openConfirmDialog = (device: DeviceType) => {
    setDeviceToBeRemoved(device);
    setConfirmDialogOpen(true);
  };

  const splitDevicesIfConfigured = (
    devices: DeviceType[],
    configured: boolean
  ): DeviceType[] => {
    if (configured) {
      return devices.filter((d: DeviceType) => d.calendarId);
    }
    return devices.filter((d: DeviceType) => !d.calendarId);
  };

  return (
    <Container className={classes.root} maxWidth={false}>
      <Toolbar
        editDevice={editDevice}
        filterDevices={filterDevices}
        hasAdminRights={hasAdminRights}
      />
      <Box mt={3}>
        <Results
          calendars={state.calendar.calendars}
          devices={splitDevicesIfConfigured(filteredDevices, true)}
          editDevice={editDevice}
          hasAdminRights={hasAdminRights}
          removeDevice={openConfirmDialog}
        />
      </Box>
      {splitDevicesIfConfigured(filteredDevices, false).length > 0 ? (
        <Box mt={3}>
          <Typography color="textPrimary" variant="h2">
            {'Unconfigured devices'}
          </Typography>
          <Results
            calendars={state.calendar.calendars}
            devices={splitDevicesIfConfigured(filteredDevices, false)}
            editDevice={editDevice}
            hasAdminRights={hasAdminRights}
            removeDevice={openConfirmDialog}
          />
        </Box>
      ) : null}
      {state.device.currentEditingDevice ? (
        <EditDevice
          calendars={state.calendar.calendars}
          closeDialog={closeDialog}
          device={state.device.currentEditingDevice}
          fullScreen={fullScreen}
          hasAdminRights={hasAdminRights}
          open={open}
          saveDevice={saveDevice}
          user={state.auth.user}
        />
      ) : null}
      {confirmDialogOpen && deviceToBeRemoved !== null ? (
        <DeleteAlertDialog
          open={confirmDialogOpen}
          fullScreen={fullScreen}
          handleClose={closeConfirmDialog}
          confirmDelete={removeDevice}
          dialogContent={`Are you sure you want to delete device: "${deviceToBeRemoved.id}"?`}
        />
      ) : null}
    </Container>
  );
};

export default DeviceListView;
