import React, { PropsWithChildren, forwardRef, Ref } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

interface DeleteAlertDialogProps extends PropsWithChildren<unknown> {
  confirmDelete: () => void;
  dialogContent: string;
  fullScreen: boolean;
  handleClose: () => void;
  open: boolean;
}

const DeleteAlertDialog = forwardRef(
  (props: DeleteAlertDialogProps, ref: Ref<unknown>) => {
    const {
      confirmDelete,
      dialogContent,
      fullScreen,
      handleClose,
      open
    } = props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="confirm-delete-event-dialog"
        ref={ref}
      >
        <DialogTitle id="confirm-dialog-title">{`Delete`}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default DeleteAlertDialog;
