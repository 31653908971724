import React, {
  useState,
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import EditIcon from '@material-ui/icons/Edit';
import { TeamType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  }
}));

interface ResultsItemProps extends PropsWithChildren<unknown> {
  teams: TeamType[];
  editTeam: (temp: TeamType) => void;
}

const Results: FunctionComponent<ResultsItemProps> = (props) => {
  const classes = useStyles();
  const { teams, editTeam, ...rest } = props;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return teams.length ? (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={500}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.slice(0, limit).map((team: TeamType, index: number) => (
                <TableRow hover={true} key={team.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        alt="team-logo"
                        variant="circular"
                        className={classes.avatar}
                        src={team.logoURL as string}
                      >
                        {team.name}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>{team.name}</TableCell>
                  <TableCell>{team.description}</TableCell>
                  <TableCell>
                    <Button
                      className={classes.button}
                      onClick={() => editTeam(teams[index])}
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={teams.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  ) : null;
};

export default Results;
