import React, {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
  makeStyles,
  TextField,
  Avatar
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, DateTimePicker } from '@material-ui/pickers'; // TODO: moved to material-ui v5, will support min max selectable time
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import RepeatIcon from '@material-ui/icons/Loop';
import { rrulestr } from 'rrule';
import {
  CalendarEvent,
  CalendarType,
  NewCalendarEvent,
  RecurringEventSaveActionEnum,
  TeamType
} from '../../../types';
import * as recurrenceUtils from '../../../utils/recurrence';
import EditEventRecurrence from './edit-event-recurrence';
import RecurringEventChanged from './recurring-event-changed';
import moment from '../../../utils/moment';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    width: 600
  },
  title: {
    flex: 1
  },
  content: {
    padding: theme.spacing(2)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  calendarPropertyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  propertyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  propertyContainerRepeat: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  titleField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  picker: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  teamLogo: {
    marginRight: theme.spacing(2)
  },
  repeatIcon: {
    marginRight: theme.spacing(1)
  }
}));

const recurringOptions = [
  {
    title: 'Does not repeat',
    resetsRecurrent: true,
    defaultValue: '',
    openRecurrenceEdit: false
  },
  {
    title: 'Set recurrence',
    resetsRecurrent: false,
    defaultValue: 'dummy string',
    openRecurrenceEdit: true
  }
];

interface EditEventProps extends PropsWithChildren<unknown> {
  calendars: CalendarType[];
  closeDialog: () => void;
  event: CalendarEvent | NewCalendarEvent;
  fullScreen: boolean;
  open: boolean;
  recurringEvents: CalendarEvent[];
  saveEvent: (
    update: boolean,
    event: CalendarEvent | NewCalendarEvent,
    previousCalendar: CalendarType | null
  ) => void;
  teams: TeamType[];
}

const EditEvent = forwardRef((props: EditEventProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const {
    calendars,
    closeDialog,
    event,
    fullScreen,
    open,
    recurringEvents,
    saveEvent,
    teams
  } = props;
  const [calendar, setCalendar] = useState<CalendarType | null>(event.calendar);
  const [title, setTitle] = useState<string>(event.title ? event.title : '');
  const [allDay, setAllDay] = useState<boolean>(event.allDay);
  const [startDate, setStartDate] = useState<Date>(event.start);
  const [endDate, setEndDate] = useState<Date>(
    event.allDay ? moment(event.end).subtract(1, 'day').toDate() : event.end
  );
  const [teamList, setTeamList] = useState<TeamType[]>([]);
  const [recurringEditOpen, setRecurringEditOpen] = useState<boolean>(false);
  const [recurrence, setRecurrence] = useState<string>('');
  const [recurringEvent, setRecurringEvent] = useState<CalendarEvent | null>(
    null
  );
  const [recurrenceHasChanged, setRecurrenceHasChanged] = useState<boolean>(
    false
  );
  const [
    recurrenceMenuAnchorEl,
    setRecurrenceMenuAnchorEl
  ] = React.useState<null | HTMLElement>(null);
  const [
    recurringEventChangedDialogOpen,
    setRecurringEventChangedDialogOpen
  ] = useState<boolean>(false);

  useEffect(() => {
    if (event.description) {
      // parse team from string
      const parsedTeamListId = JSON.parse(event.description).map(
        (t: TeamType) => t.id
      );
      setTeamList(
        teams.filter((t: TeamType) => parsedTeamListId.includes(t.id))
      );
    }

    if ((event as CalendarEvent).recurringEventId) {
      const tempRecurringEvent = recurringEvents.find(
        (e: CalendarEvent) => e.id === (event as CalendarEvent).recurringEventId
      );
      if (tempRecurringEvent) {
        setRecurrence(
          tempRecurringEvent.recurrence ? tempRecurringEvent.recurrence[0] : ''
        );
        setRecurringEvent(tempRecurringEvent);
      }
    }
  }, [event, recurringEvents, teams]);

  const handleTitleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCalendarChange = async (
    e: ChangeEvent<unknown>,
    selectedCalendar: CalendarType | null
  ) => {
    setCalendar(selectedCalendar);
  };

  const handleAllDayChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setAllDay(e.target.checked);
  };

  const handleStartDateChange = async (date: MaterialUiPickersDate) => {
    if (date) {
      setStartDate(date.toDate());
      if (date.toDate() >= endDate) {
        setEndDate(date.add(1, 'hours').toDate());
      }
      // update recurrence when startDate changes if exists
      if (recurrence !== '') {
        const newRecurrence = recurrenceUtils.updateRecurrence(
          recurrence,
          date.toDate()
        );
        setRecurrence(newRecurrence);
        if (recurrence !== newRecurrence) {
          setRecurrenceHasChanged(true);
        }
      }
    }
  };

  const handleEndDateChange = async (date: MaterialUiPickersDate) => {
    if (date) {
      setEndDate(date.toDate());
      if (date.toDate() <= startDate) {
        const newStartDate = date.subtract(1, 'hours').toDate();
        setStartDate(newStartDate);
        // update recurrence when startDate changes if exists
        if (recurrence !== '') {
          const newRecurrence = recurrenceUtils.updateRecurrence(
            recurrence,
            newStartDate
          );
          setRecurrence(newRecurrence);
          if (recurrence !== newRecurrence) {
            setRecurrenceHasChanged(true);
          }
        }
      }
    }
  };

  const handleTeamsChange = async (
    e: ChangeEvent<unknown>,
    selectedTeams: TeamType[]
  ) => {
    setTeamList(selectedTeams);
  };

  const isSaveDisabled = () => {
    return title === '' || calendar === null || teamList.length === 0;
  };

  const save = () => {
    if ((event as CalendarEvent).recurringEventId) {
      setRecurringEventChangedDialogOpen(true);
    } else {
      saveThisEvent();
    }
  };

  const saveRecurringEvent = () => {
    if (recurringEvent) {
      const dates = recurrenceUtils.fixDateWhenRecurring(
        startDate,
        endDate,
        recurrence
      );

      const update = recurringEvent.calendar ? true : false;
      let previousCalendar = null;
      // handle move between calendars, only when a previous event is changed
      if (update && recurringEvent.calendar) {
        previousCalendar =
          recurringEvent.calendar.calendarId !== calendar?.calendarId
            ? event.calendar
            : null;
      }

      const newEvent: NewCalendarEvent = {
        start: dates.startDate,
        end: allDay
          ? moment(dates.endDate).add(1, 'day').toDate()
          : dates.endDate, // handle all-day events
        title,
        allDay,
        description: JSON.stringify(teamList),
        calendar,
        recurrence: [recurrence]
      };

      saveEvent(update, { ...recurringEvent, ...newEvent }, previousCalendar);
    }
  };

  const saveThisEvent = () => {
    const dates = recurrenceUtils.fixDateWhenRecurring(
      startDate,
      endDate,
      recurrence
    );
    const update = event.calendar ? true : false;
    let previousCalendar = null;

    // handle move between calendars, only when a previous event is changed
    if (update && event.calendar) {
      previousCalendar =
        event.calendar.calendarId !== calendar?.calendarId
          ? event.calendar
          : null;
    }

    const newEvent: NewCalendarEvent = {
      start: dates.startDate,
      end: allDay
        ? moment(dates.endDate).add(1, 'day').toDate()
        : dates.endDate, // handle all-day events
      title,
      allDay,
      description: JSON.stringify(teamList),
      calendar,
      recurrence: undefined
    };

    // if event recurrence and recurrence differ then update recurring event
    if (!update && recurrence !== '') {
      newEvent.recurrence = [recurrence];
    }

    saveEvent(update, { ...event, ...newEvent }, previousCalendar);
  };

  const saveRecurrence = (newRecurrence: string) => {
    // fix dates when setting recurrence
    const dates = recurrenceUtils.fixDateWhenRecurring(
      startDate,
      endDate,
      newRecurrence
    );
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    setRecurrence(newRecurrence);
    setRecurrenceHasChanged(true);
    setRecurringEditOpen(false);
  };

  const closeRecurringEditDialog = () => {
    setRecurringEditOpen(false);
  };

  const closeRecurringEventDialog = () => {
    setRecurringEventChangedDialogOpen(false);
  };

  const handleSaveRecurringEvent = (eventType: string) => {
    if (eventType === RecurringEventSaveActionEnum.THIS_EVENT) {
      saveThisEvent();
    } else if (eventType === RecurringEventSaveActionEnum.ALL_EVENTS) {
      saveRecurringEvent();
    }
  };

  const handleRecurrenceMenuClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRecurrenceMenuAnchorEl(e.currentTarget);
  };

  const handleRecurrenceMenuItemClick = (
    e: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    if (recurringOptions[index].resetsRecurrent) {
      setRecurrence(recurringOptions[index].defaultValue);
      setRecurrenceHasChanged(true);
    } else if (recurringOptions[index].openRecurrenceEdit) {
      setRecurringEditOpen(true);
    }
    setRecurrenceMenuAnchorEl(null);
  };

  const handleRecurrenceMenuClose = () => {
    setRecurrenceMenuAnchorEl(null);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Title" />
            <TextField
              className={classes.titleField}
              fullWidth={true}
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <Box className={classes.calendarPropertyContainer}>
              <Typography variant="body1">{`Room`}</Typography>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <Autocomplete
                  id="calendar-select-outlined"
                  value={calendar}
                  onChange={handleCalendarChange}
                  options={calendars}
                  getOptionLabel={(option) => option.summary}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Avatar
                        alt="calendar-color"
                        variant="circular"
                        className={classes.avatar}
                        style={{
                          color: option.backgroundColor,
                          backgroundColor: option.backgroundColor
                        }}
                      >
                        {option.summary}
                      </Avatar>
                      {option.summary}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <Box className={classes.propertyContainer}>
              <Typography variant="body1">{'All-day'}</Typography>
              <Switch
                checked={allDay}
                onChange={handleAllDayChange}
                color="primary"
                name="Allday event"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </ListItem>
          <ListItem className={classes.listItem}>
            {allDay ? (
              <Box className={classes.propertyContainer}>
                <DatePicker
                  className={classes.picker}
                  inputVariant="outlined"
                  format="ddd, DD MMM yyyy"
                  label="Start date"
                  views={['year', 'month', 'date']}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  className={classes.picker}
                  inputVariant="outlined"
                  format="ddd, DD MMM yyyy"
                  label="End date"
                  views={['year', 'month', 'date']}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </Box>
            ) : (
              <Box className={classes.propertyContainer}>
                <DateTimePicker
                  className={classes.picker}
                  autoOk={true}
                  ampm={false}
                  inputVariant="outlined"
                  format="ddd, DD MMM yyyy      HH:mm"
                  minutesStep={30}
                  value={startDate}
                  onChange={handleStartDateChange}
                  label="Start date"
                />
                <DateTimePicker
                  className={classes.picker}
                  autoOk={true}
                  ampm={false}
                  inputVariant="outlined"
                  format="ddd, DD MMM yyyy      HH:mm"
                  minutesStep={30}
                  value={endDate}
                  onChange={handleEndDateChange}
                  label="End date"
                />
              </Box>
            )}
          </ListItem>
          <ListItem className={classes.listItem}>
            <Box className={classes.propertyContainerRepeat}>
              <RepeatIcon className={classes.repeatIcon} fontSize={'inherit'} />
              <Typography variant="body1" onClick={handleRecurrenceMenuClick}>
                {recurrence === ''
                  ? 'Does not repeat'
                  : rrulestr(recurrence).toText()}
              </Typography>
            </Box>
            <Menu
              id="recurrence-menu"
              anchorEl={recurrenceMenuAnchorEl}
              keepMounted={true}
              open={Boolean(recurrenceMenuAnchorEl)}
              onClose={handleRecurrenceMenuClose}
            >
              {recurringOptions.map((r, index) => (
                <MenuItem
                  key={r.title}
                  onClick={(e) => handleRecurrenceMenuItemClick(e, index)}
                >
                  {r.title}
                </MenuItem>
              ))}
            </Menu>
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <Box className={classes.calendarPropertyContainer}>
              <Typography variant="body1">{'Teams'}</Typography>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <Autocomplete
                  multiple={true}
                  id="teams-select-outlined"
                  value={teamList}
                  onChange={handleTeamsChange}
                  options={teams}
                  disableCloseOnSelect={true}
                  filterSelectedOptions={true}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name as string}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Avatar
                        className={classes.teamLogo}
                        src={option.logoURL as string}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="secondary">
          Cancel
        </Button>
        <Button disabled={isSaveDisabled()} onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
      {recurringEditOpen ? (
        <EditEventRecurrence
          closeDialog={closeRecurringEditDialog}
          fullScreen={fullScreen}
          open={recurringEditOpen}
          recurrence={recurrence}
          saveRecurrence={saveRecurrence}
          startDate={startDate}
        />
      ) : null}
      {recurringEventChangedDialogOpen ? (
        <RecurringEventChanged
          closeDialog={closeRecurringEventDialog}
          fullScreen={fullScreen}
          open={recurringEventChangedDialogOpen}
          recurrenceHasChanged={recurrenceHasChanged}
          type={'EDIT'}
          updateAction={handleSaveRecurringEvent}
        />
      ) : null}
    </Dialog>
  );
});

export default EditEvent;
