import { CalendarEvent } from '../../types';
import {
  ActionType,
  AddEventAction,
  AddRecurringEventAction,
  RemoveEventAction,
  RemoveRecurringEventAction,
  SetEventsAction,
  SetRecurringEventsAction,
  UpdateEventAction,
  UpdateRecurringEventAction,
  UpdateRecurringEventsAction
} from './types';

export const addEvent = (event: CalendarEvent): AddEventAction => {
  return {
    type: ActionType.ADD_EVENT,
    payload: event
  };
};

export const updateEvent = (event: CalendarEvent): UpdateEventAction => {
  return {
    type: ActionType.UPDATE_EVENT,
    payload: event
  };
};

export const removeEvent = (event: CalendarEvent): RemoveEventAction => {
  return {
    type: ActionType.REMOVE_EVENT,
    payload: event
  };
};

export const updateRecurringEvents = (
  recurringEventId: string,
  events: CalendarEvent[]
): UpdateRecurringEventsAction => {
  return {
    type: ActionType.UPDATE_RECURRING_EVENTS,
    payload: { recurringEventId, events }
  };
};

export const addRecurringEvent = (
  recurringEvent: CalendarEvent
): AddRecurringEventAction => {
  return {
    type: ActionType.ADD_RECURRING_EVENT,
    payload: recurringEvent
  };
};

export const updateRecurringEvent = (
  recurringEvent: CalendarEvent
): UpdateRecurringEventAction => {
  return {
    type: ActionType.UPDATE_RECURRING_EVENT,
    payload: recurringEvent
  };
};

export const removeRecurringEvent = (
  recurringEvent: CalendarEvent
): RemoveRecurringEventAction => {
  return {
    type: ActionType.REMOVE_RECURRING_EVENT,
    payload: recurringEvent
  };
};

export const setEvents = (events: CalendarEvent[]): SetEventsAction => {
  return {
    type: ActionType.SET_EVENTS,
    payload: events
  };
};

export const setRecurringEvents = (
  recurringEvents: CalendarEvent[]
): SetRecurringEventsAction => {
  return {
    type: ActionType.SET_RECURRING_EVENTS,
    payload: recurringEvents
  };
};
