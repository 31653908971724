import produce from 'immer';
import { DeviceType } from '../../types';
import { ActionType, DeviceActions, DeviceState } from './types';

export const initialDeviceState: DeviceState = {
  devices: [],
  currentEditingDevice: null
};

export const deviceReducer = (
  state: DeviceState,
  action: DeviceActions
): DeviceState => {
  switch (action.type) {
    case ActionType.ADD_DEVICE: {
      if (!state.devices.find((d: DeviceType) => d.id === action.payload.id)) {
        return produce(state, (draftState) => {
          draftState.devices.push(action.payload);
          draftState.devices.sort((d1: DeviceType, d2: DeviceType) => {
            if (!d1.id || !d2.id) {
              return 0;
            }
            return d1.id.localeCompare(d2.id);
          });
        });
      }
      return state;
    }
    case ActionType.REMOVE_DEVICE: {
      return produce(state, (draftState) => {
        draftState.devices = draftState.devices.filter(
          (d: DeviceType) => d.id !== action.payload.id
        );
      });
    }
    case ActionType.UPDATE_DEVICE: {
      return produce(state, (draftState) => {
        const deviceIndex = draftState.devices.findIndex(
          (d: DeviceType) => d.id === action.payload.id
        );
        draftState.devices[deviceIndex] = action.payload;
        draftState.devices.sort((d1: DeviceType, d2: DeviceType) => {
          if (!d1.id || !d2.id) {
            return 0;
          }
          return d1.id.localeCompare(d2.id);
        });
      });
    }
    case ActionType.SET_DEVICES: {
      return produce(state, (draftState) => {
        draftState.devices = action.payload;
      });
    }
    case ActionType.SET_CURRENT_EDITING_DEVICE: {
      return produce(state, (draftState) => {
        draftState.currentEditingDevice = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};
