import produce from 'immer';
import { CalendarType } from '../../types';
import { ActionType, CalendarActions, CalendarState } from './types';

export const initialCalendarState: CalendarState = {
  calendars: [],
  isCalendarsFetched: false,
  currentSelectedCalendar: null
};

export const calendarReducer = (
  state: CalendarState,
  action: CalendarActions
): CalendarState => {
  switch (action.type) {
    case ActionType.SET_CALENDARS: {
      return produce(state, (draftState) => {
        draftState.calendars = action.payload;
      });
    }
    case ActionType.UPDATE_CALENDAR_EXTRAS: {
      return produce(state, (draftState) => {
        const calendar = draftState.calendars.find(
          (c: CalendarType) => c.id === action.payload.calendarId
        );
        if (calendar) {
          calendar.extras = action.payload.extras;
        }
      });
    }
    case ActionType.SET_IS_CALENDARS_FETCHED: {
      return produce(state, (draftState) => {
        draftState.isCalendarsFetched = action.payload;
      });
    }
    case ActionType.SET_CURRENT_SELECTED_CALENDAR: {
      return produce(state, (draftState) => {
        draftState.currentSelectedCalendar = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};
