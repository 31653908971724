import React, {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useState,
  useEffect
} from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { DatePicker } from '@material-ui/pickers'; // TODO: moved to material-ui v5, will support min max selectable time
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from '../../../utils/moment';
import { RRule, rrulestr, Options, Weekday } from 'rrule';
import * as recurrenceUtils from '../../../utils/recurrence';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(1)
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  listItemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  repeatEveryInput: {
    backgroundColor: colors.grey[200],
    borderRadius: 4,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  titleField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  weekdayIconContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  weekdayCheckbox: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  weekdayIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: theme.spacing(1.5)
  },
  weekdayIconChecked: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: colors.blue[900],
    fontSize: theme.spacing(1.5)
  },
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  radioButtonOptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.grey[200],
    padding: theme.spacing(1),
    borderRadius: 4
  },
  radioButtonLabel: {
    width: theme.spacing(20)
  },
  picker: {
    backgroundColor: colors.grey[200],
    padding: theme.spacing(1),
    borderRadius: 4
  },
  nbrOfOcurrences: {
    marginRight: theme.spacing(1),
    width: 60
  }
}));

interface EditEventRecurrenceProps extends PropsWithChildren<unknown> {
  closeDialog: () => void;
  fullScreen: boolean;
  open: boolean;
  recurrence: string;
  saveRecurrence: (recurrence: string) => void;
  startDate: Date;
}

enum MonthlyOnEnum {
  MONTHLY_ON_DAY = 'MONTHLY_ON_DAY',
  MONTHLY_NTN_WEEKDAY = 'MONTHLY_NTN_WEEKDAY'
}
enum EndsOnEnum {
  NEVER = 'NEVER',
  ON = 'ON',
  AFTER = 'AFTER'
}

const EditEventRecurrence = forwardRef(
  (props: EditEventRecurrenceProps, ref: Ref<unknown>) => {
    const classes = useStyles();
    const {
      closeDialog,
      fullScreen,
      open,
      recurrence,
      saveRecurrence,
      startDate
    } = props;
    const [
      repeatEveryIntervalValue,
      setRepeatEveryIntervalValue
    ] = useState<number>(1);
    const [
      repeatEveryIntervalType,
      setRepeatEveryIntervalType
    ] = useState<number>(RRule.WEEKLY);
    const [monthlyOnType, setMonthlyOnType] = useState<string>(
      MonthlyOnEnum.MONTHLY_ON_DAY
    );
    const [
      ntnOccurenceOfDayInMonth,
      setNtnOccurenceOfDayInMonth
    ] = useState<number>(0);
    const [selectedWeekdays, setSelectedWeekdays] = useState<Weekday[]>([
      RRule.MO
    ]);
    const [endsOnSelection, setEndsOnSelection] = useState<string>(
      EndsOnEnum.NEVER
    );
    const [endsOnDate, setEndsOnDate] = useState<Date>(
      moment(new Date()).add(3, 'month').toDate()
    );
    const [weekStart, setWeekStart] = useState<Weekday>(RRule.MO);
    const [nbrOfOcurrencesValue, setNbrOfOcurrencesValue] = useState<number>(1);

    useEffect(() => {
      if (recurrence !== '') {
        const tempRRule = rrulestr(recurrence);

        setRepeatEveryIntervalValue(tempRRule.options.interval);
        setRepeatEveryIntervalType(tempRRule.options.freq);
        if (tempRRule.options.byweekday) {
          const tempSelectedWeekdays: Weekday[] = [];
          for (const weekdayInt of tempRRule.options.byweekday) {
            tempSelectedWeekdays.push(
              recurrenceUtils.getWeekdayFromWeekdayInt(weekdayInt)
            );
          }
          setSelectedWeekdays(tempSelectedWeekdays);
        }

        setWeekStart(
          recurrenceUtils.getWeekdayFromWeekdayInt(tempRRule.options.wkst)
        );

        if (tempRRule.options.until) {
          setEndsOnSelection(EndsOnEnum.ON);
          setEndsOnDate(tempRRule.options.until);
        } else if (tempRRule.options.count) {
          setEndsOnSelection(EndsOnEnum.AFTER);
          setNbrOfOcurrencesValue(tempRRule.options.count);
        } else {
          setEndsOnSelection(EndsOnEnum.NEVER);
        }

        if (
          tempRRule.options.freq === RRule.MONTHLY &&
          tempRRule.options.byweekday
        ) {
          setMonthlyOnType(MonthlyOnEnum.MONTHLY_NTN_WEEKDAY);
        }
      }
    }, [recurrence]);

    useEffect(() => {
      setNtnOccurenceOfDayInMonth(
        recurrenceUtils.getNtnOccurenceOfDayInMonth(startDate)
      );
    }, [startDate]);

    const handleRepeatEveryValueChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      if ((event.target.value as number) > 0) {
        setRepeatEveryIntervalValue(event.target.value as number);
      }
    };

    const handleRepeatEveryIntervalTypeChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setRepeatEveryIntervalType(event.target.value as number);
    };

    const handleMonthlyOnTypeChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setMonthlyOnType(event.target.value as string);
    };

    const handleWeekdayCheckboxChange = (selectedWeekday: Weekday) => {
      let tempSelectedWeekdays = [...selectedWeekdays];
      if (!selectedWeekdays.includes(selectedWeekday)) {
        tempSelectedWeekdays.push(selectedWeekday);
      } else if (selectedWeekdays.length > 1) {
        tempSelectedWeekdays = selectedWeekdays.filter(
          (w: Weekday) => w !== selectedWeekday
        );
      }
      setSelectedWeekdays(tempSelectedWeekdays);
    };

    const handleEndsOnSelection = (event: ChangeEvent<HTMLInputElement>) => {
      setEndsOnSelection(event.target.defaultValue);
    };

    const handleEndsOnDateChange = async (date: MaterialUiPickersDate) => {
      if (date) {
        setEndsOnDate(date.toDate());
      }
    };

    const handleNbrOfOcurrencesValueChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      if ((event.target.value as number) > 0) {
        setNbrOfOcurrencesValue(event.target.value as number);
      }
    };

    const handleSaveRecurrence = () => {
      const ruleOptions: Partial<Options> = {};
      ruleOptions.freq = repeatEveryIntervalType;

      if (endsOnSelection === EndsOnEnum.ON) {
        ruleOptions.until = endsOnDate;
      } else if (endsOnSelection === EndsOnEnum.AFTER) {
        ruleOptions.count = nbrOfOcurrencesValue;
      }

      if (repeatEveryIntervalValue > 1) {
        ruleOptions.interval = repeatEveryIntervalValue;
      }

      if (repeatEveryIntervalType === RRule.WEEKLY) {
        ruleOptions.wkst = weekStart;
        ruleOptions.byweekday = selectedWeekdays;
      } else if (
        repeatEveryIntervalType === RRule.MONTHLY &&
        monthlyOnType === MonthlyOnEnum.MONTHLY_NTN_WEEKDAY
      ) {
        ruleOptions.byweekday = [
          recurrenceUtils
            .getWeekdayFromWeekdayInt(moment(startDate).day() - 1)
            .nth(ntnOccurenceOfDayInMonth)
        ];
      }
      saveRecurrence(new RRule(ruleOptions).toString());
    };

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => closeDialog()}
        aria-labelledby="event-recurrence-edit"
        ref={ref}
      >
        <DialogTitle id="event-recurrence-edit-title">{`Event recurrence`}</DialogTitle>
        <DialogContent className={classes.content}>
          <List>
            <ListItem>
              <Box className={classes.listItemRow}>
                <Typography component="div" variant="body1">
                  {`Repeat every`}
                </Typography>
                <TextField
                  style={{ width: 60 }}
                  className={classes.repeatEveryInput}
                  type="number"
                  value={repeatEveryIntervalValue}
                  onChange={handleRepeatEveryValueChange}
                />
                <FormControl className={classes.repeatEveryInput}>
                  <Select
                    value={repeatEveryIntervalType}
                    onChange={handleRepeatEveryIntervalTypeChange}
                  >
                    <MenuItem value={RRule.DAILY}>
                      {repeatEveryIntervalValue > 1 ? 'days' : 'day'}
                    </MenuItem>
                    <MenuItem value={RRule.WEEKLY}>
                      {repeatEveryIntervalValue > 1 ? 'weeks' : 'week'}
                    </MenuItem>
                    <MenuItem value={RRule.MONTHLY}>
                      {repeatEveryIntervalValue > 1 ? 'months' : 'month'}
                    </MenuItem>
                    <MenuItem value={RRule.YEARLY}>
                      {repeatEveryIntervalValue > 1 ? 'years' : 'year'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </ListItem>
            {repeatEveryIntervalType === RRule.WEEKLY ? (
              <ListItem className={classes.listItem}>
                <Typography component="div" variant="body1">
                  {`Repeat on`}
                </Typography>
                <Box className={classes.weekdayIconContainer}>
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>M</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>M</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.MO)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.MO)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>T</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>T</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.TU)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.TU)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>W</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>W</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.WE)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.WE)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>T</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>T</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.TH)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.TH)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>F</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>F</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.FR)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.FR)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>S</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>S</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.SA)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.SA)}
                  />
                  <Checkbox
                    className={classes.weekdayCheckbox}
                    icon={<Avatar className={classes.weekdayIcon}>S</Avatar>}
                    checkedIcon={
                      <Avatar className={classes.weekdayIconChecked}>S</Avatar>
                    }
                    checked={selectedWeekdays.includes(RRule.SU)}
                    onChange={() => handleWeekdayCheckboxChange(RRule.SU)}
                  />
                </Box>
              </ListItem>
            ) : null}
            {repeatEveryIntervalType === RRule.MONTHLY ? (
              <ListItem className={classes.listItem}>
                <FormControl className={classes.repeatEveryInput}>
                  <Select
                    value={monthlyOnType}
                    onChange={handleMonthlyOnTypeChange}
                  >
                    <MenuItem value={MonthlyOnEnum.MONTHLY_ON_DAY}>
                      {`Monthly on day ${moment(startDate).date()}`}
                    </MenuItem>
                    <MenuItem value={MonthlyOnEnum.MONTHLY_NTN_WEEKDAY}>
                      {`Monthly on day the ${recurrenceUtils.getNthString(
                        ntnOccurenceOfDayInMonth
                      )} ${moment(startDate).format('dddd')}`}
                    </MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            ) : null}
            <ListItem className={classes.listItem}>
              <Typography component="div" variant="body1">
                {`Ends`}
              </Typography>
              <RadioGroup
                aria-label="recurring-event-type"
                name="recurring-event-type"
                value={endsOnSelection}
                onChange={handleEndsOnSelection}
              >
                <Box className={classes.radioButtonContainer}>
                  <FormControlLabel
                    className={classes.radioButtonLabel}
                    value={EndsOnEnum.NEVER}
                    control={<Radio />}
                    label="Never"
                  />
                </Box>
                <Box className={classes.radioButtonContainer}>
                  <FormControlLabel
                    className={classes.radioButtonLabel}
                    value={EndsOnEnum.ON}
                    control={<Radio />}
                    label="On"
                  />
                  <DatePicker
                    className={classes.picker}
                    format="ddd, DD MMM yyyy"
                    views={['year', 'month', 'date']}
                    value={endsOnDate}
                    onChange={handleEndsOnDateChange}
                    disabled={endsOnSelection !== EndsOnEnum.ON}
                  />
                </Box>
                <Box className={classes.radioButtonContainer}>
                  <FormControlLabel
                    className={classes.radioButtonLabel}
                    value={EndsOnEnum.AFTER}
                    control={<Radio />}
                    label="After"
                  />
                  <Box className={classes.radioButtonOptionContainer}>
                    <TextField
                      className={classes.nbrOfOcurrences}
                      type="number"
                      value={nbrOfOcurrencesValue}
                      onChange={handleNbrOfOcurrencesValueChange}
                      disabled={endsOnSelection !== EndsOnEnum.AFTER}
                    />
                    <Typography component="div" variant="body1">
                      {`ocurrences`}
                    </Typography>
                  </Box>
                </Box>
              </RadioGroup>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveRecurrence} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default EditEventRecurrence;
