import { DeviceType } from '../../types';

export enum ActionType {
  SET_DEVICES = 'SET_DEVICES',
  ADD_DEVICE = 'ADD_DEVICE',
  REMOVE_DEVICE = 'REMOVE_DEVICE',
  UPDATE_DEVICE = 'UPDATE_DEVICE',
  SET_CURRENT_EDITING_DEVICE = 'SET_CURRENT_EDITING_DEVICE'
}

export interface SetDevicesAction {
  type: ActionType.SET_DEVICES;
  payload: DeviceType[];
}

export interface AddDeviceAction {
  type: ActionType.ADD_DEVICE;
  payload: DeviceType;
}

export interface RemoveDeviceAction {
  type: ActionType.REMOVE_DEVICE;
  payload: DeviceType;
}

export interface UpdateDeviceAction {
  type: ActionType.UPDATE_DEVICE;
  payload: DeviceType;
}

export interface SetCurrentEditingDeviceAction {
  type: ActionType.SET_CURRENT_EDITING_DEVICE;
  payload: DeviceType | null;
}

export type DeviceState = {
  devices: DeviceType[];
  currentEditingDevice: DeviceType | null;
};

export type DeviceActions =
  | SetDevicesAction
  | AddDeviceAction
  | RemoveDeviceAction
  | UpdateDeviceAction
  | SetCurrentEditingDeviceAction;
