import React, {
  ChangeEvent,
  PropsWithChildren,
  Ref,
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  Avatar,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import LogoGrid from '../../../components/image/logo-grid';
import LogoListItem from '../../..//components/image/logo-list-item';
import FileSizeAlertDialog from '../../..//components/dialog/file-size-alert';
import * as firebase from '../../../services/firebase';
import { User, CalendarType, DeviceType } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  appBar: {
    position: 'relative',
    minWidth: 600
  },
  calendarPropertyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  input: {
    display: 'none'
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  textField: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2)
  },
  title: {
    flex: 1
  }
}));

interface EditDeviceProps extends PropsWithChildren<unknown> {
  calendars: CalendarType[];
  closeDialog: () => void;
  device: DeviceType;
  fullScreen: boolean;
  hasAdminRights: boolean;
  open: boolean;
  saveDevice: (device: DeviceType) => void;
  user: User;
}

const EditDevice = forwardRef((props: EditDeviceProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const theme = useTheme();
  const logoGridFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    calendars,
    closeDialog,
    device,
    fullScreen,
    hasAdminRights,
    open,
    saveDevice,
    user
  } = props;
  const [deviceId, setDeviceId] = useState<string>(device.id as string);
  const [calendar, setCalendar] = useState<CalendarType | null>(null);
  const [infoHeader, setInfoHeader] = useState<string>(
    device.infoHeader as string
  );
  const [infoText, setInfoText] = useState<string>(device.infoText as string);
  const [logoURL, setLogoURL] = useState<string>(device.logoURL as string);
  const [logoGridOpen, setLogoGridOpen] = useState<boolean>(false);
  const [imageUrlList, setImageUrlList] = useState<string[]>([]);
  const [fileSizeAlertOpen, setFileSizeAlertOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const listUserDeviceLogos = async () => {
    const tempImageUrlList: string[] = [];
    const storageItems = await firebase
      .getUserDeviceLogosRef(user.uid)
      .listAll();
    for (const imageRef of storageItems.items) {
      tempImageUrlList.push(await imageRef.getDownloadURL());
    }
    setImageUrlList(tempImageUrlList);
  };

  useEffect(() => {
    listUserDeviceLogos();
  }, [user]);

  useEffect(() => {
    if (device.calendarId && getCalendarById(device.calendarId)) {
      setCalendar(getCalendarById(device.calendarId));
    }
  }, [device, calendars]);

  const getCalendarById = (calendarId: string) => {
    const resCalendar = calendars.find((c) => c.calendarId === calendarId);
    return resCalendar ? resCalendar : null;
  };

  const handleIdChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDeviceId(event.target.value);
  };

  const handleCalendarChange = async (
    e: ChangeEvent<unknown>,
    selectedCalendar: CalendarType | null
  ) => {
    setCalendar(selectedCalendar);
  };

  const handleInfoHeaderChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInfoHeader(event.target.value);
  };

  const handleInfoTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInfoText(event.target.value);
  };

  const selectLogo = (selectedLogoURL: string) => {
    setLogoURL(selectedLogoURL);
    setLogoGridOpen(false);
  };

  const closeLogoGrid = () => {
    setLogoGridOpen(false);
  };

  const isEmpty = (value: string) => {
    return !value || value.trim() === '';
  };

  const isSaveDisabled = () => {
    return (
      isEmpty(deviceId) ||
      calendar === null ||
      isEmpty(infoHeader) ||
      isEmpty(infoText) ||
      isEmpty(logoURL)
    );
  };

  const save = () => {
    const updatedDevice: DeviceType = {
      id: device.id ? device.id : deviceId.trim(),
      calendarId: calendar?.calendarId as string,
      calendarShortId: calendar?.id as string,
      infoHeader,
      infoText,
      logoURL,
      roomName: calendar?.summary as string
    };
    saveDevice(updatedDevice);
  };

  const handleCloseFileSizeAlert = () => {
    setFileSizeAlertOpen(false);
  };

  const handleFireBaseUpload = (image: File) => {
    setUploading(true);
    const imageExt = image.name.split('.').pop();
    const newFilename = `${uuid()}.${imageExt}`;
    // async magic goes here...
    const uploadTask = firebase
      .getUserDeviceLogosRef(user.uid)
      //      .child(image.name)
      .child(newFilename)
      .put(image);
    // initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      () => {
        // ongoing updates, do nothing
      },
      () => {
        // catches the errors
        setUploading(false);
      },
      () => {
        setUploading(false);

        // reload images
        listUserDeviceLogos();

        firebase
          .getUserDeviceLogosRef(user.uid)
          .child(newFilename)
          .getDownloadURL()
          .then((fireBaseUrl: string) => {
            setLogoURL(fireBaseUrl);
          });
      }
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
      ref={ref}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {device.id ? device.id : 'New device'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <DialogContentText>{`Edit the device's information.`}</DialogContentText>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Id *" secondary="Device id." />
            <TextField
              className={classes.textField}
              error={deviceId !== null && isEmpty(deviceId)}
              fullWidth={true}
              id="name-required"
              variant="outlined"
              value={deviceId ? deviceId : ''}
              disabled={device.id !== null && !hasAdminRights}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleIdChange(e)}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <Box className={classes.calendarPropertyContainer}>
              <Typography variant="body1">{`Room *`}</Typography>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <Autocomplete
                  id="calendar-select-outlined"
                  value={calendar}
                  onChange={handleCalendarChange}
                  options={calendars}
                  getOptionLabel={(option) => option.summary}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Avatar
                        alt="calendar-color"
                        variant="circular"
                        className={classes.avatar}
                        style={{
                          color: option.backgroundColor,
                          backgroundColor: option.backgroundColor
                        }}
                      >
                        {option.summary}
                      </Avatar>
                      {option.summary}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Information headline *"
              secondary="Headline for additional infomation."
            />
            <TextField
              className={classes.textField}
              error={infoHeader !== null && isEmpty(infoHeader)}
              fullWidth={true}
              id="name-required"
              variant="outlined"
              value={infoHeader ? infoHeader : ''}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleInfoHeaderChange(e)}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Information text *"
              secondary="Additional information text."
            />
            <TextField
              className={classes.textField}
              error={infoText !== null && isEmpty(infoText)}
              fullWidth={true}
              id="name-required"
              variant="outlined"
              value={infoText ? infoText : ''}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleInfoTextChange(e)}
            />
          </ListItem>
          <Divider />
          <LogoListItem
            handleFireBaseUpload={handleFireBaseUpload}
            imageUrlList={imageUrlList}
            listItemClass={classes.listItem}
            logoURL={logoURL}
            setFileSizeAlertOpen={setFileSizeAlertOpen}
            setLogoGridOpen={setLogoGridOpen}
            uploading={uploading}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="secondary">
          Cancel
        </Button>
        <Button disabled={isSaveDisabled()} onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
      {logoGridOpen ? (
        <LogoGrid
          open={logoGridOpen}
          fullScreen={logoGridFullScreen}
          closeDialog={closeLogoGrid}
          selectLogo={selectLogo}
          imageUrlList={imageUrlList}
        />
      ) : null}
      {fileSizeAlertOpen ? (
        <FileSizeAlertDialog
          open={fileSizeAlertOpen}
          handleClose={handleCloseFileSizeAlert}
        />
      ) : null}
    </Dialog>
  );
});

export default EditDevice;
