import React, { PropsWithChildren, forwardRef, Ref } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

interface FileSizeAlertDialogProps extends PropsWithChildren<unknown> {
  open: boolean;
  handleClose: () => void;
}

const FileSizeAlertDialog = forwardRef(
  (props: FileSizeAlertDialogProps, ref: Ref<unknown>) => {
    const { handleClose, open } = props;

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        ref={ref}
      >
        <DialogTitle id="alert-dialog-title">{'File size too big'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select an image with the size of 1Mb or less.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default FileSizeAlertDialog;
