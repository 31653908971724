import { TeamType } from '../../types';

export enum ActionType {
  SET_TEAMS = 'SET_TEAMS',
  ADD_TEAM = 'ADD_TEAM',
  REMOVE_TEAM = 'REMOVE_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  SET_CURRENT_EDITING_TEAM = 'SET_CURRENT_EDITING_TEAM'
}

export interface SetTeamsAction {
  type: ActionType.SET_TEAMS;
  payload: TeamType[];
}

export interface AddTeamAction {
  type: ActionType.ADD_TEAM;
  payload: TeamType;
}

export interface RemoveTeamAction {
  type: ActionType.REMOVE_TEAM;
  payload: TeamType;
}

export interface UpdateTeamAction {
  type: ActionType.UPDATE_TEAM;
  payload: TeamType;
}

export interface SetCurrentEditingTeamAction {
  type: ActionType.SET_CURRENT_EDITING_TEAM;
  payload: TeamType | null;
}

export type TeamState = {
  teams: TeamType[];
  currentEditingTeam: TeamType | null;
};

export type TeamActions =
  | SetTeamsAction
  | AddTeamAction
  | RemoveTeamAction
  | UpdateTeamAction
  | SetCurrentEditingTeamAction;
