import { User } from '../../types';
import { ActionType, SetIsAuthenticatedAction, SetUserAction } from './types';

export const setIsAuthenticated = (
  isAuthenticated: boolean
): SetIsAuthenticatedAction => {
  return {
    type: ActionType.SET_IS_AUTHENTICATED,
    payload: isAuthenticated
  };
};

export const setUser = (user: User): SetUserAction => {
  return {
    type: ActionType.SET_USER,
    payload: user
  };
};
