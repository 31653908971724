import React, {
  useContext,
  useReducer,
  createContext,
  FunctionComponent,
  PropsWithChildren
} from 'react';
import combineReducers from 'react-combine-reducers';
import { appReducer, initialAppState } from './app/reducer';
import { AppState, AppActions } from './app/types';
import { authReducer, initialAuthState } from './auth/reducer';
import { AuthState, AuthActions } from './auth/types';
import { calendarReducer, initialCalendarState } from './calendar/reducer';
import { CalendarState, CalendarActions } from './calendar/types';
import { deviceReducer, initialDeviceState } from './device/reducer';
import { DeviceState, DeviceActions } from './device/types';
import { eventReducer, initialEventState } from './event/reducer';
import { EventState, EventActions } from './event/types';
import { teamReducer, initialTeamState } from './team/reducer';
import { TeamState, TeamActions } from './team/types';

interface State {
  app: AppState;
  auth: AuthState;
  calendar: CalendarState;
  device: DeviceState;
  event: EventState;
  team: TeamState;
}

type Action =
  | AppActions
  | AuthActions
  | CalendarActions
  | DeviceActions
  | EventActions
  | TeamActions;

type Dispatch = (action: Action) => void;

type RootReducer = (state: State, action: Action) => State;

const [rootReducer, initialState] = combineReducers<RootReducer>({
  calendar: [calendarReducer, initialCalendarState],
  device: [deviceReducer, initialDeviceState],
  event: [eventReducer, initialEventState],
  team: [teamReducer, initialTeamState],
  auth: [authReducer, initialAuthState],
  app: [appReducer, initialAppState]
});

const AppContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const AppContextProvider: FunctionComponent<unknown> = (
  props: PropsWithChildren<unknown>
) => {
  const [state, dispatch] = useReducer<RootReducer>(rootReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

const useAppContext = (): { state: State; dispatch: Dispatch } => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};

export { AppContextProvider, useAppContext };
