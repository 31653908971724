export const SCOPE_CALENDAR = 'https://www.googleapis.com/auth/calendar';

export const SCOPE_CALENDAR_EVENTS =
  'https://www.googleapis.com/auth/calendar.events';

export const DISCOVERY_DOCS_CALENDAR = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
];

export const DEFAULT_DEVICE = {
  id: null,
  calendarId: null,
  calendarShortId: null,
  infoHeader: null,
  infoText: null,
  logoURL: null,
  roomName: null
};

export const DEFAULT_TEAM = {
  id: null,
  name: null,
  description: null,
  logoURL: null
};

export const DEFAULT_USER = {
  displayName: null,
  email: null,
  photoURL: null,
  uid: null
};
