import { rrulestr, RRule, Weekday, Options } from 'rrule';
import { DurationInputArg2 } from 'moment';
import moment from './moment';

type FixedDateType = {
  startDate: Date;
  endDate: Date;
};

export const getNtnOccurenceOfDayInMonth = (startDate: Date): number => {
  const startDateMoment = moment(startDate);
  const dayOfMonthMoment = startDateMoment.clone().startOf('month');
  let tempNtnOccurenceOfDayInMonth = 0;
  while (dayOfMonthMoment.date() <= startDateMoment.date()) {
    tempNtnOccurenceOfDayInMonth++;
    dayOfMonthMoment.add(7, 'days');
  }
  return tempNtnOccurenceOfDayInMonth;
};

export const getNthString = (nth: number): string => {
  switch (nth) {
    case 1: {
      return 'first';
    }
    case 2: {
      return 'second';
    }
    case 3: {
      return 'third';
    }
    case 4: {
      return 'fourth';
    }
    case 5: {
      return 'fift';
    }
    default: {
      return 'first';
    }
  }
};

export const getWeekdayFromWeekdayInt = (weekdayInt: number): Weekday => {
  switch (weekdayInt) {
    case RRule.MO.weekday: {
      return RRule.MO;
    }
    case RRule.TU.weekday: {
      return RRule.TU;
    }
    case RRule.WE.weekday: {
      return RRule.WE;
    }
    case RRule.TH.weekday: {
      return RRule.TH;
    }
    case RRule.FR.weekday: {
      return RRule.FR;
    }
    case RRule.SA.weekday: {
      return RRule.SA;
    }
    case RRule.SU.weekday: {
      return RRule.SU;
    }
    default:
      return RRule.MO;
  }
};

export const updateRecurrence = (
  recurrence: string,
  startDate: Date
): string => {
  const rruleOptions = rrulestr(recurrence).options;
  const newRruleOptions: Partial<Options> = {};
  if (
    rruleOptions.freq === RRule.WEEKLY &&
    rruleOptions.byweekday &&
    rruleOptions.byweekday.length === 1
  ) {
    // set options from current
    newRruleOptions.freq = rruleOptions.freq;
    newRruleOptions.wkst = rruleOptions.wkst;
    newRruleOptions.interval =
      rruleOptions.interval > 1 ? rruleOptions.interval : undefined;
    newRruleOptions.until = rruleOptions.until;
    newRruleOptions.count = rruleOptions.count;

    // update weekday with new data
    newRruleOptions.byweekday = [
      getWeekdayFromWeekdayInt(moment(startDate).day() - 1)
    ];
    return new RRule(newRruleOptions).toString();
  } else if (rruleOptions.freq === RRule.MONTHLY && rruleOptions.bynweekday) {
    // set options from current
    const nthOccurenceOfDatInMonth = getNtnOccurenceOfDayInMonth(startDate);
    newRruleOptions.freq = rruleOptions.freq;
    newRruleOptions.interval =
      rruleOptions.interval > 1 ? rruleOptions.interval : undefined;
    newRruleOptions.until = rruleOptions.until;
    newRruleOptions.count = rruleOptions.count;

    // update weekday with new data
    newRruleOptions.byweekday = [
      getWeekdayFromWeekdayInt(moment(startDate).day() - 1).nth(
        nthOccurenceOfDatInMonth
      )
    ];
    return new RRule(newRruleOptions).toString();
  }
  return recurrence;
};

export const fixDateWhenRecurring = (
  startDate: Date,
  endDate: Date,
  newRecurrence: string
): FixedDateType => {
  const returnDates = {
    startDate,
    endDate
  };
  if (newRecurrence !== '') {
    const rrule = rrulestr(newRecurrence);
    rrule.options.dtstart = startDate;
    const startEndDateDiff = moment(endDate).diff(moment(startDate), 'hours');
    let intervalType: DurationInputArg2 = 'days';
    if (rrule.options.freq === RRule.WEEKLY) {
      intervalType = 'weeks';
    } else if (rrule.options.freq === RRule.MONTHLY) {
      intervalType = 'months';
    } else if (rrule.options.freq === RRule.YEARLY) {
      intervalType = 'years';
    }
    if (rrule.options.freq === RRule.WEEKLY) {
      const rruleDates = rrule.between(
        startDate,
        moment(startDate).add(rrule.options.interval, intervalType).toDate()
      );

      if (rruleDates.length > 0) {
        const dateDiff =
          moment(rruleDates[0])
            .startOf('day')
            .diff(moment(startDate).startOf('day'), 'days') % 7;
        if (dateDiff > 0) {
          const newStartDate = moment(startDate).add(dateDiff, 'days');
          returnDates.startDate = newStartDate.toDate();
          returnDates.endDate = newStartDate
            .add(startEndDateDiff, 'hours')
            .toDate();
        }
      }
    }
  }
  return returnDates;
};
