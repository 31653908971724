import { gapi } from 'gapi-script';
import {
  CalendarType,
  CalendarEvent,
  NewCalendarEvent,
  RecurringEventFetchType
} from '../types';
import {
  DISCOVERY_DOCS_CALENDAR,
  SCOPE_CALENDAR,
  SCOPE_CALENDAR_EVENTS
} from '../constants';
import moment from '../utils/moment';

interface GoogleCalendarType {
  accessRole: string;
  backgroundColor: string;
  colorId: string;
  etag: string;
  foregroundColor: string;
  id: string;
  kind: string;
  primary: boolean;
  selected: boolean;
  summary: string;
  timeZone: string;
}

interface GoogleEventType {
  calendarId: string | undefined;
  created: Date;
  creator: {
    displayName: string;
    email: string;
  };
  description: string;
  end: {
    date: string;
    dateTime: string;
    timeZone: string | undefined;
  };
  etag: string;
  eventId: string | undefined;
  htmlLink: string;
  iCalUID: string;
  id: string;
  kind: string;
  organizer: {
    email: string;
    displayName: string;
    self: boolean;
  };
  recurrence: string[] | undefined;
  reminders: {
    useDefault: boolean;
  };
  sequence: number;
  start: {
    date: string;
    dateTime: string;
    timeZone: string | undefined;
  };
  recurringEventId: string | undefined;
  status: string;
  summary: string;
  updated: Date;
  originalStartTime:
    | undefined
    | {
        date: string | undefined;
        dateTime: string;
        timeZone: string;
      };
}

interface ToGoogleEventType {
  eventId: string | undefined;
  calendarId: string | undefined;
  summary: string;
  description: string;
  start:
    | { date: string }
    | {
        dateTime: string;
        timeZone: string | undefined;
      };
  end:
    | { date: string }
    | {
        dateTime: string;
        timeZone: string | undefined;
      };
  recurrence: string[] | undefined;
}

class FetchError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const init = async (): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      gapi.load('client:auth2', async () => {
        await gapi.client.init({
          apiKey: process.env.REACT_APP_API_KEY,
          clientId: process.env.REACT_APP_CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS_CALENDAR,
          scope: [SCOPE_CALENDAR, SCOPE_CALENDAR_EVENTS].join(' ')
        });
        resolve(gapi.auth2.getAuthInstance());
      });
    } catch (error) {
      reject(error);
    }
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signIn = async (): Promise<any> =>
  await gapi.auth2.getAuthInstance().signIn();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signOut = async (): Promise<any> =>
  await gapi.auth2.getAuthInstance().signOut();

export const isSignedIn = async (): Promise<boolean> =>
  await gapi.auth2.getAuthInstance().isSignedIn.get();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentUser = async (): Promise<any> =>
  await gapi.auth2.getAuthInstance().currentUser.get();

export const getUserEmail = async (): Promise<string> =>
  gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();

export const fetchEvents = async (
  calendars: CalendarType[],
  min: moment.Moment,
  max: moment.Moment
): Promise<CalendarEvent[]> => {
  let events: GoogleEventType[] = [];
  const batch = gapi.client.newBatch();
  for (const calendar of calendars) {
    batch.add(
      gapi.client.calendar.events.list({
        calendarId: calendar.calendarId,
        showDeleted: false,
        singleEvents: true,
        timeMin: min.toISOString(),
        timeMax: max.toISOString(),
        maxResults: 2500
      }),
      { id: calendar.id }
    );
  }
  const response = await batch;
  for (const calendarId of Object.keys(response.result)) {
    const { error, result } = response.result[calendarId];
    if (error) {
      throw new FetchError('Error while fetching events', error);
    }
    events = events.concat(result.items);
  }

  return events.map((event: GoogleEventType) =>
    fromGoogleEventFormat(event, calendars)
  );
};

export const fetchRecurringEvents = async (
  recurringEvents: RecurringEventFetchType[],
  calendars: CalendarType[]
): Promise<CalendarEvent[]> => {
  // INFO: cannot fetch different calendars in same batch
  const events: GoogleEventType[] = [];
  const recurringCalendarIds = recurringEvents
    .map((e) => e.calendarId)
    // remove duplicates
    .filter((v, i, a) => a.indexOf(v) === i);
  for (const recurringCalendarId of recurringCalendarIds) {
    const batch = gapi.client.newBatch();
    const recurringCalendarEvents = recurringEvents.filter(
      (e) => e.calendarId === recurringCalendarId
    );
    for (const recurringEvent of recurringCalendarEvents) {
      batch.add(
        gapi.client.calendar.events.get({
          calendarId: recurringEvent.calendarId,
          eventId: recurringEvent.id
        })
      );
    }
    const response = await batch;
    for (const recurringEventId of Object.keys(response.result)) {
      const { error, result } = response.result[recurringEventId];
      if (error) {
        throw new FetchError('Error while fetching events', error);
      }
      events.push(result);
    }
  }

  return events.map((event: GoogleEventType) =>
    fromGoogleEventFormat(event, calendars)
  );
};

export const fetchCalendarList = async (): Promise<CalendarType[]> => {
  try {
    const response = await gapi.client.calendar.calendarList.list();
    const { status, result } = response;
    if (status === 200) {
      const calendars = result.items.filter(
        (c: GoogleCalendarType) => c.accessRole === 'owner' && !c.primary
      );
      return calendars.map((c: GoogleCalendarType) =>
        fromGoogleCalendarFormat(c)
      );
    }
    return [];
  } catch (error) {
    throw new FetchError('Error while fetching calendars', error);
  }
};

export const fromGoogleCalendarFormat = (
  calendar: GoogleCalendarType
): CalendarType => {
  return {
    backgroundColor: calendar.backgroundColor,
    etag: calendar.etag,
    foregroundColor: calendar.foregroundColor,
    calendarId: calendar.id,
    id: calendar.id.split('@')[0],
    kind: calendar.kind,
    summary: calendar.summary,
    timeZone: calendar.timeZone,
    extras: {
      nbrOfPeople: 0,
      nbrOfShowers: 0,
      nbrOfToilets: 0
    }
  };
};

export const fromGoogleCalendarEventFormat = (
  event: GoogleEventType,
  calendar: CalendarType
): CalendarEvent => {
  return {
    id: event.id,
    start: moment(event.start.dateTime || event.start.date)
      .utc()
      .toDate(),
    end: moment(event.end.dateTime || event.end.date)
      .utc()
      .toDate(),
    title: event.summary,
    description: event.description,
    allDay: !!event.start.date,
    calendar,
    creator: event.creator,
    created: event.created,
    updated: event.updated,
    recurringEventId: event.recurringEventId,
    recurrence: event.recurrence,
    originalStartTime: event.originalStartTime
  };
};

export const fromGoogleEventFormat = (
  event: GoogleEventType,
  calendars: CalendarType[]
): CalendarEvent => {
  const calendar = calendars.find(
    (cal) => event.organizer.email === cal.calendarId
  ) || {
    summary: 'external',
    id: event.organizer.email,
    calendarId: event.organizer.email,
    backgroundColor: '#444',
    foregroundColor: '#444',
    extras: {
      nbrOfPeople: 0,
      nbrOfShowers: 0,
      nbrOfToilets: 0
    },
    kind: 'kind',
    timeZone: 'timeZone',
    etag: 'etag'
  };

  return {
    id: event.id,
    start: moment(event.start.dateTime || event.start.date)
      .utc()
      .toDate(),
    end: moment(event.end.dateTime || event.end.date)
      .utc()
      .toDate(),
    title: event.summary,
    description: event.description,
    allDay: !!event.start.date,
    calendar,
    creator: event.creator,
    created: event.created,
    updated: event.updated,
    recurringEventId: event.recurringEventId,
    recurrence: event.recurrence,
    originalStartTime: event.originalStartTime
  };
};

export const toGoogleEventFormat = (
  event: CalendarEvent | NewCalendarEvent
): ToGoogleEventType => ({
  eventId: 'id' in event ? event.id : undefined,
  calendarId: event.calendar?.calendarId,
  summary: event.title,
  description: event.description,
  start: event.allDay
    ? { date: moment(event.start).format('YYYY-MM-DD') }
    : {
        dateTime: moment(event.start).format('YYYY-MM-DDTHH:mm:ssZZ'),
        timeZone: event.recurrence ? 'Europe/Stockholm' : undefined
      },
  end: event.allDay
    ? { date: moment(event.end).format('YYYY-MM-DD') }
    : {
        dateTime: moment(event.end).format('YYYY-MM-DDTHH:mm:ssZZ'),
        timeZone: event.recurrence ? 'Europe/Stockholm' : undefined
      },
  recurrence: event.recurrence
});

export const updateEvent = async (
  event: CalendarEvent,
  previousCalendar: CalendarType | null,
  calendars: CalendarType[]
): Promise<CalendarEvent> => {
  if (previousCalendar?.calendarId) {
    await gapi.client.calendar.events.move({
      calendarId: previousCalendar?.calendarId,
      eventId: event.id,
      destination: event.calendar?.calendarId
    });
  }
  const response = await gapi.client.calendar.events.update(
    toGoogleEventFormat(event)
  );
  return fromGoogleEventFormat(response.result, calendars);
};

export const createEvent = async (
  event: NewCalendarEvent,
  calendars: CalendarType[]
): Promise<CalendarEvent> => {
  const response = await gapi.client.calendar.events.insert(
    toGoogleEventFormat(event)
  );
  return fromGoogleEventFormat(response.result, calendars);
};

export const deleteEvent = async (event: CalendarEvent): Promise<void> => {
  await gapi.client.calendar.events.delete({
    eventId: event.id,
    calendarId: event.calendar?.calendarId
  });
};
