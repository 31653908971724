import { User } from '../../types';

export enum ActionType {
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_USER = 'SET_USER'
}

export interface SetIsAuthenticatedAction {
  type: ActionType.SET_IS_AUTHENTICATED;
  payload: boolean;
}

export interface SetUserAction {
  type: ActionType.SET_USER;
  payload: User;
}

export type AuthState = {
  user: User;
  isAuthenticated: boolean;
};
export type AuthActions = SetUserAction | SetIsAuthenticatedAction;
